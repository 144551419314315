import { HYDRATE } from 'next-redux-wrapper';
import {
    SET_ORDER_POPUP_ANSWERS,
    TOGGLE_POPUP,
    TOGGLE_ORDER_POPUP_VISIBILITY,
    TOGGLE_ACTIVITY_DETAILS_POPUP_VISIBILITY,
    SET_ORDER_POPUP_CALENDAR,
    TOGGLE_MESSAGES_INFO_POPUP_VISIBILITY,
    TOGGLE_ADD_SERVICE_POPUP_VISIBILITY,
    TOGGLE_PRO_INBOX_DETAILS_POPUP_VISIBILITY,
} from '../actions/types';

const initialState = {
    orderServicePopup: {
        visibility: false,
        messageFirst: false,
        questions: [],
        answeredQuestions: [],
        timeQuestions: [],
    },
    activityDetailsPopup: {
        visibility: false,
        data: [],
    },
    messagesInfoPopup: {
        visibility: false,
        data: null,
    },
    addServicePopup: false,
    proInboxDetailsPopup: false,
};

export const popups = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.popups,
            };
        case TOGGLE_POPUP:
            return {
                ...state,
                ...payload,
            };
        case TOGGLE_ORDER_POPUP_VISIBILITY:
            return {
                ...state,
                orderServicePopup: {
                    ...state.orderServicePopup,
                    visibility: payload,
                },
            };
        case TOGGLE_ACTIVITY_DETAILS_POPUP_VISIBILITY:
            return {
                ...state,
                activityDetailsPopup: {
                    ...state.activityDetailsPopup,
                    visibility: payload,
                },
            };

        case TOGGLE_PRO_INBOX_DETAILS_POPUP_VISIBILITY:
            return {
                ...state,
                proInboxDetailsPopup: payload,
            };
        case TOGGLE_ADD_SERVICE_POPUP_VISIBILITY:
            return {
                ...state,
                addServicePopup: payload,
            };
        case TOGGLE_MESSAGES_INFO_POPUP_VISIBILITY:
            return {
                ...state,
                messagesInfoPopup: {
                    ...state.messagesInfoPopup,
                    visibility: payload.visibility,
                    data: payload.data,
                },
            };
        case SET_ORDER_POPUP_CALENDAR:
            return {
                ...state,
                orderServicePopup: {
                    ...state.orderServicePopup,
                    calendar: payload,
                },
            };
        case SET_ORDER_POPUP_ANSWERS:
            return {
                ...state,
                orderServicePopup: {
                    ...state.orderServicePopup,
                    answeredQuestions: payload,
                },
            };
        default:
            return state;
    }
};
