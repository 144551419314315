import axios from '../utils/axios';
import { SINGUP_WITH_PHONE_NUMBER } from './routes';

interface SignupWithPhoneNumberArs {
    firstName: string;
    lastName?: string;
    phone: number;
    countryCode: string;
}

export const signupWithPhoneNumber = ({ firstName, lastName, phone, countryCode }: SignupWithPhoneNumberArs) => axios({
    method: 'post',
    url: process.env.BACKEND_URL + SINGUP_WITH_PHONE_NUMBER,
    data: {
        firstName,
        lastName,
        phone,
        countryCode,
    },
});