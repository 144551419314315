import { DESC } from 'actions/types';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../utils/axios';
import { Finish, Nexts, Prevs, Text01, Text02, Text03, Text05, Text06, Text07, Text08, Text09, Text10, Text11, Text12, Text13, Text14, ToolT1 } from 'helpers/dictionary';

const CustomSteps = ({id,myfunction,lead,message,service}) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const lang = useSelector((state) => state.settings.lang);
    const steps = [
        {
            title: 'First',
            content: <p dangerouslySetInnerHTML={{__html: Text01[lang]}}></p>,
        },
        {
            title: 'Second',
            content: <p dangerouslySetInnerHTML={{__html: Text02[lang]}}></p>,
        },
        {
            title: 'Last',
            content: <p dangerouslySetInnerHTML={{__html: Text03[lang]}}></p>,
        },
    ];
    const servsteps = [
        {
            title: 'First',
            content: <p dangerouslySetInnerHTML={{__html: Text12[lang]}}></p>,
        },
        {
            title: 'Second',
            content: <p dangerouslySetInnerHTML={{__html: Text13[lang]}}></p>,
        },
        {
            title: 'Last',
            content: <p dangerouslySetInnerHTML={{__html: Text14[lang]}}></p>,
        },
    ];
    const stepsMsg = [
        {
            title: 'First',
            content: <p dangerouslySetInnerHTML={{__html: Text10[lang]}}></p>,
        },
        {
            title: 'Second',
            content: <p dangerouslySetInnerHTML={{__html: Text11[lang]}}></p>,
        },

    ];
    const stepsLead = [
        {
            title: 'First',
            content: <p dangerouslySetInnerHTML={{__html: ToolT1[lang]}}></p>,
        },
        {
            title: 'Second',
            content: <p dangerouslySetInnerHTML={{__html: Text05[lang]}}></p>,
        },
        {
            title: 'Last',
            content: <p dangerouslySetInnerHTML={{__html: Text06[lang]}}></p>,
        },
        {
            title: 'First',
            content: <p dangerouslySetInnerHTML={{__html: Text07[lang]}}></p>,
        },
        {
            title: 'Second',
            content: <p dangerouslySetInnerHTML={{__html: Text08[lang]}}></p>,
        },
        {
            title: 'Last',
            content: <p dangerouslySetInnerHTML={{__html: Text09[lang]}}></p>,
        },
    ];

    const next = () => {

        myfunction(id+1);
        dispatch({
            type: DESC,
            payload: id+1
        });

    };

    const prev = () => {
        dispatch({
            type: DESC,
            payload: id-1
        });
        myfunction(id-1);

    };

    const setTool=(args)=>{
        axios.put(`api/v1/user/tooltip/${user.id}`,{tooltip:args});
    };

    return (
        <>
            {lead?
                <>

                    <div className="steps-content">{stepsLead[id-1].content}</div>
                    <div className="steps-action" style={{textAlign:'right'}}>
                        <span style={{float:'left'}}>{id} of {stepsLead.length}</span>
                        {id > 1 && (
                            <span style={{ margin: '0 8px',color:'#d1d1d1', cursor:'pointer' }} onClick={() => prev()}>
                                {Prevs[lang]}
                            </span>
                        )}
                        {id<6 && (
                            <Button style={{borderRadius: '4px' ,width:'40%'}} type="primary" onClick={() => next()}>
                                {Nexts[lang]}
                            </Button>
                        )}
                        {id==6&& (
                            <Button style={{borderRadius: '4px', width:'40%'}} type="primary" onClick={() => {setTool('lead2');myfunction(-1);}}>
                                {Finish[lang]}
                            </Button>
                        )}

                    </div>
                </>:
                message?
                    <>

                        <div className="steps-content">{stepsMsg[id-1].content}</div>
                        <div className="steps-action" style={{textAlign:'right'}}>
                            <span style={{float:'left'}}>{id} of {stepsMsg.length}</span>
                            {id > 1 && (
                                <span style={{ margin: '0 8px',color:'#d1d1d1', cursor:'pointer' }} onClick={() => prev()}>
                                    {Prevs[lang]}
                                </span>
                            )}
                            {id<2&& (
                                <Button style={{borderRadius: '4px' ,width:'40%'}} type="primary" onClick={() => next()}>
                                    {Nexts[lang]}
                                </Button>
                            )}
                            {id==2&& (
                                <Button style={{borderRadius: '4px', width:'40%'}} type="primary" onClick={() => {myfunction(-1); setTool('msg2');}}>
                                    {Finish[lang]}
                                </Button>
                            )}

                        </div>
                    </>:service? <>

                        <div className="steps-content">{servsteps[id-1].content}</div>
                        <div className="steps-action" style={{textAlign:'right'}}>
                            <span style={{float:'left'}}>{id} of {servsteps.length}</span>
                            {id > 1 && (
                                <span style={{ margin: '0 8px',color:'#d1d1d1', cursor:'pointer' }} onClick={() => prev()}>
                                    {Prevs[lang]}
                                </span>
                            )}
                            {id<3 && (
                                <Button style={{borderRadius: '4px' ,width:'40%'}} type="primary" onClick={() => next()}>
                                    {Nexts[lang]}
                                </Button>
                            )}
                            {id==3&& (
                                <Button style={{borderRadius: '4px', width:'40%'}} type="primary" onClick={() => {myfunction(-1); setTool('serv2');}}>
                                    {Finish[lang]}
                                </Button>
                            )}

                        </div>

                    </>:


                        <>

                            <div className="steps-content">{steps[id-1].content}</div>
                            <div className="steps-action" style={{textAlign:'right'}}>
                                <span style={{float:'left'}}>{id} of {steps.length}</span>
                                {id > 1 && (
                                    <span style={{ margin: '0 8px',color:'#d1d1d1', cursor:'pointer' }} onClick={() => prev()}>
                                        {Prevs[lang]}
                                    </span>
                                )}
                                {id<3 && (
                                    <Button style={{borderRadius: '4px' ,width:'40%'}} type="primary" onClick={() => next()}>
                                        {Nexts[lang]}
                                    </Button>
                                )}
                                {id==3&& (
                                    <Button style={{borderRadius: '4px', width:'40%'}} type="primary" onClick={() => {myfunction(-1); setTool('lead1');}}>
                                        {Finish[lang]}
                                    </Button>
                                )}

                            </div>
                        </> }
        </>
    );
};


export default CustomSteps;
