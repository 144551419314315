import App from 'next/app';
import dynamic from 'next/dynamic';
import React from 'react';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import cookie from 'cookie';
import 'antd/dist/antd.css';
import { loadUser } from '../actions/auth';
import { AUTH_ERROR, CHANGE_LANG, USER_LOADED } from '../actions/types';
import GlobalStyle from '../styles/global';
import { theme } from '../styles/theme';
import '../style.css';
import { appWithTranslation } from 'next-i18next';
import { compose } from 'redux';
import { ErrorBoundary } from 'shared';
import axios from '../utils/axios';
import { getCookie } from 'utils/cookie';
import { reduxWrapper } from 'store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = dynamic(()=> import('../components/Layout'));

class MyApp extends App {
    static getInitialProps = reduxWrapper.getInitialAppProps(store => async ({ Component, ctx, router }) => {
        const cookieLocale = getCookie('NEXT_LOCALE', ctx.req);
        const routerLocale = router.locale;

        const locale = cookieLocale || routerLocale;

        if(locale !== routerLocale){
            if(ctx.req){
                if (ctx.req.url === '/search') { return { pageProps: {}, }; }
                // server
                ctx.res.writeHead(302, { Location: `/${locale}${router.asPath}` });
                ctx.res.end();
                return {};
            } else {
            // client
                router.push(router.asPath, router.asPath, { locale });
                return {};
            }
        }

        store.dispatch({
            type: CHANGE_LANG,
            payload: locale
        });

        if(typeof window !== 'undefined'){
            await store.dispatch(loadUser());
        } else {
            store.dispatch({ type: 'SET_EXTRA', payload: ctx.req.cookies });
            try {
                const parsedCookies = cookie.parse(ctx.req.headers.cookie || '');
                const { 'user-token': userToken } = parsedCookies;
                const [, domain] = process.env.BACKEND_URL.split('//');

                if(userToken) {
                    axios.defaults.headers.get.Cookie = `user-token=${userToken};domain=${domain}`;
                    const { data } = await axios.get(process.env.BACKEND_URL + '/api/v1/pro', {
                        withCredentials: true,
                    });
                    const { pro, ...user } = data;
                    const currentUser = {
                        user,
                        pro: user.status === 'pro' && pro || null,
                        isAuthenticated: true,
                        loading: false,
                    };
                    store.dispatch({ type: USER_LOADED, payload: currentUser });
                }

            } catch (error) {
                store.dispatch({
                    type: AUTH_ERROR,
                    payload: [error, cookie.parse(ctx.req.headers.cookie || '')]
                });
                // eslint-disable-next-line no-console
                console.error('failed to fetch user', error);
            }
        }

        let pageProps = {};

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        return { pageProps };
    });


    render() {
        const { Component, pageProps } = this.props;

        const ComponentWithErrorBoundary = () => (
            <ErrorBoundary>
                <Component {...pageProps} />
            </ErrorBoundary>
        );
        return (
            <>
                <Head>

                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/favicons/apple-touch-icon.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/favicons/favicon-32x32.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/favicons/favicon-16x16.png"
                    />
                    <link rel="manifest" href="/favicons/site.webmanifest" />
                    <meta name="msapplication-TileColor" content="#da532c" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
                </Head>
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                    <ToastContainer />
                    {!pageProps.search
                        ? (
                            <Layout>
                                <ComponentWithErrorBoundary />
                            </Layout>
                        )
                        : <ComponentWithErrorBoundary />}
                </ThemeProvider>

            </>
        );
    }
}

export default compose(
    appWithTranslation,
    reduxWrapper.withRedux,
)(MyApp);
