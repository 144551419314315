import { HYDRATE } from 'next-redux-wrapper';
import { DESC } from '../actions/types';

const initialState = {
    counter: null,

};

export const tooltip = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.tooltip,
            };
        case DESC:
            return {
                counter: payload
            };

        default:
            return state;
    }
};
