import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
:root {
  --color-grey: #959595;
  --color-green: #22A886;
  --color-indigo: #4d089a;
  --color-french-violet: #7b31c9;
  --color-jet: #2b2b2b;
  --color-flickr-blue: #2E7CF6;
  --color-green-pantone: #42ab44;
  --color-red: #DE4B40;
  --color-red-shade: #FDD7D4;
  --color-flickr-blue-shade: #EBF0FF;
  --color-grey-100: #7E858E;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--color-flickr-blue);
}
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  font-size: 62.5%;
}


body {
  margin: 0;
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 400;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}


sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input { /* 1 */
  overflow: visible;
}

button,
select { /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-400px * 10))}
}

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Content {
  transform: translateX(-4rem);
  transition: transform 200ms ease-in-out;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
  @media (min-width: 1025px) {
    transform: translateY(4rem);
  }
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 102;
  display: flex;
    justify-content: center;
    align-items: center;
  @media (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
  .ReactModal__Content {
    transform: translateX(0);
    @media (min-width: 1025px) {
    transform: translateY(0);
    }
  }
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
  .ReactModal__Content {
    transform: translateX(-4rem);
    @media (min-width: 1025px) {
    transform: translateY(4rem);
    }
  }
}
`;

export default GlobalStyle;
