import { combineReducers } from 'redux';
import { settings } from './settings';
import { modals } from './modals';
import { popups } from './popups';
import { auth } from './auth';
import { messages } from './messages';
import { leads } from './leads';
import { questions } from './questions';
import {profile} from './profile';
import {tooltip} from './tooltip';
const reducers = {
    settings,
    tooltip,
    modals,
    popups,
    auth,
    leads,
    messages,
    questions,
    profile
};

export default combineReducers(reducers);
