import axios from '../utils/axios';
import { UPDATE_PRO_EMAIL } from './routes';

interface UpdateProArs {
    email: string;
}

export const updateProEmail = ({ email }: UpdateProArs) => axios({
    method: 'post',
    url: process.env.BACKEND_URL + UPDATE_PRO_EMAIL,
    withCredentials: true,
    data: {
        email,
    },
});