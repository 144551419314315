import { createWrapper } from 'next-redux-wrapper';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initStore = (_context) => createStore(
    reducers,
    composeWithDevTools(
        applyMiddleware(thunkMiddleware)
    ),
);


export const reduxWrapper = createWrapper(initStore);
