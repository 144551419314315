import { HYDRATE } from 'next-redux-wrapper';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    SET_AUTH_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    USER_LOGOUT,
    UPDATE_PRO_COINS,
    SWITCH_TO_PRO,
    SWITCH_TO_CLIENT,
    ADD_NEW_PRO_TEMPLATE,
} from '../actions/types';

import { setCookie } from '../utils/cookie';

const initialState = {
    isAuthenticated: null,
    user: null,
    pro: null,
    loading: false,
    errors: [],
};

export const auth = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SET_EXTRA':
            return {
                ...state,
                extra: payload,
            };
        case HYDRATE:
            return {
                ...state,
                ...payload.auth,
            };
        case UPDATE_PRO_COINS:
            return {
                ...state,
                pro: {
                    ...state.pro,
                    coin: {
                        ...state.pro.count,
                        count: payload,
                    },
                },
            };
        case SET_AUTH_LOADING:
            return {
                ...state,
                loading: payload,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                user: payload,
                isAuthenticated: true,
                loading: false,
            };
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case USER_LOGOUT:
            return {
                ...state,
                errors: payload || [],
                isAuthenticated: false,
                user: null,
                pro: null,
                loading: false,
            };
        case USER_LOADED:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
            };
        case SWITCH_TO_PRO:
            setCookie('user-status', 'pro');
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: {
                    ...state.user,
                    status: 'pro',
                    isPro: true,
                },
            };
        case SWITCH_TO_CLIENT:
            setCookie('user-status', 'customer');
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: {
                    ...state.user,
                    status: 'customer',
                    isPro: false,
                },
            };
        case ADD_NEW_PRO_TEMPLATE:
            return {
                ...state,
                pro: {
                    ...state.pro,
                    templates: [...state.pro.templates, payload],
                },
            };
        default:
            return state;
    }
};
