import Router from 'next/router';
import { Passwords_dont_match } from '../helpers/dictionary';
import axios from '../utils/axios';
import {  removeCookie, setCookie } from '../utils/cookie';
import {
    AUTH_ERROR,
    LEADS_LOADED,
    REGISTER_FAIL,
    SET_AUTH_LOADING,
    USER_LOADED,
    USER_LOGOUT,
} from './types';
import { validateNumberAndSignUp } from '../api';

export const logout = () => async (dispatch) => {
    await axios('/api/v1/auth/logout', {
        method: 'post',
        withCredentials: true,
    });
    localStorage.removeItem('User');
    localStorage.clear();
    removeCookie('user-status');

    await Router.replace('/');

    dispatch({
        type: USER_LOGOUT,
    });
};

export const loadUser = () => async (dispatch) => {
    try {
        const { data } = await axios.get(process.env.BACKEND_URL + '/api/v1/pro', {
            withCredentials: true,
        });

        if (data.status === 'pro') {

            setCookie('user-status', 'pro');
            localStorage.setItem('proid',data.pro.id);

            localStorage.removeItem('userId');
            const pro = data.pro;

            delete data.pro;

            localStorage.setItem('User', data?(data.firstName):null);
            dispatch({
                type: USER_LOADED,
                payload: {
                    user: data,
                    pro,
                },
            });
        } else {
            localStorage.setItem('User', data?(data.firstName):null);
            localStorage.setItem('userId',data.id);
            localStorage.removeItem('proid');
            dispatch({
                type: USER_LOADED,
                payload: {
                    user: data,
                },
            });
        }
    } catch (error) {
        localStorage.removeItem('User');
        return dispatch({
            type: AUTH_ERROR,
        });
    }
};

export const register = ({
    registerFormNumber,
    confirmationCode,
    number,
    countryCode,
    name,
    surname,
    email,
    password,
    confirmPassword,
    reload = false,
    lang = 'eng',
}) => async (dispatch) => {
    if (registerFormNumber) {
        if (!number) {
            return dispatch({
                type: AUTH_ERROR,
                payload: ['please add a phone number.'],
            });
        }
    } else {
        if (!password) {
            return dispatch({
                type: AUTH_ERROR,
                payload: ['please add a password.'],
            });
        }

        if (password !== confirmPassword) {
            return dispatch({
                type: AUTH_ERROR,
                payload: [Passwords_dont_match[lang]],
            });
        }

        if(!confirmationCode) {
            return dispatch({
                type: AUTH_ERROR,
                payload: ['please enter confirmation code'],
            });
        }
    }

    try {
        dispatch({
            type: SET_AUTH_LOADING,
            payload: true,
        });

        if (registerFormNumber) {
            await validateNumberAndSignUp({ phone: number, countryCode, code: confirmationCode, password });
        } else {
            await axios({
                method: 'post',
                url: process.env.BACKEND_URL + '/api/v1/auth/signup',
                withCredentials: true,
                data: {
                    email,
                    firstName: name,
                    lastName: surname,
                    password,
                },
            });
        }

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });

        const { data } = await axios.get(
            process.env.BACKEND_URL + '/api/v1/auth/login',
            {
                withCredentials: true,
            }
        );

        if (data.status === 'pro') {
            setCookie('user-status', 'pro');
            localStorage.setItem('proid', data?(data.pro.id):null);
            const proData = await axios.get(`/api/v1/pro`, {
                withCredentials: true,
            });
            localStorage.setItem('User', data?(data.user.firstName):null);
            localStorage.setItem('userId', null);

            const payload = {
                user: data.user,
                pro: proData.data,
            };

            dispatch({
                type: USER_LOADED,
                payload,
            });
        } else {
            localStorage.setItem('User', data?(data.firstName):null);
            localStorage.setItem('userId', data?(data.id):null);
            dispatch({
                type: USER_LOADED,
                payload: {
                    user: data,
                },
            });
        }

        if (reload) {
            Router.reload();
        } else {
            Router.replace('/');
        }
    } catch (err) {
        const { response } = err;

        const errors = response.data || [];

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });

        dispatch({
            type: REGISTER_FAIL,
            payload: errors,
        });
        throw err;
    }
};

export const registerProfessional = ({
    name,
    email,
    phone,
    code,
    password,
    service,
    answers,
    districts,
}) => async (dispatch) => {
    try {
        dispatch({
            type: SET_AUTH_LOADING,
            payload: true,
        });

        await axios({
            method: 'post',
            url: process.env.BACKEND_URL + '/api/v1/pro',
            withCredentials: true,
            data: {
                email,
                phone,
                code,
                name,
                password,
                service,
                answers,
                districts
            },
        });

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });

        const { data } = await axios.get(`/api/v1/pro`, {
            withCredentials: true,
        });

        const payload = {
            user: data,
            pro: data.pro,
        };

        setCookie('user-status', 'pro');
        localStorage.setItem('User', data ?(data.firstName):null);
        localStorage.setItem('proid', data && data.pro ?(data.pro.id):null);

        dispatch({
            type: USER_LOADED,
            payload,
        });

        const proLeads = await axios.get(`/api/v1/order/pro-leads?id=${localStorage.getItem('proid')}`, {
            withCredentials: true,
        });

        dispatch({
            type: LEADS_LOADED,
            payload: proLeads.data,
        });

    } catch (err) {
        const { response } = err;

        const errors = response.data || [];

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });

        dispatch({
            type: REGISTER_FAIL,
            payload: errors,
        });
    }
};

export const registerRequestService = ({
    name,
    email,
    password,
    serviceName,
    categoryId,
}) => async (dispatch) => {
    dispatch({
        type: SET_AUTH_LOADING,
        payload: true,
    });

    await axios.post('/api/v1/pro/with-requested-service', {
        name,
        email,
        password,
        serviceName,
        categoryId,
    });

    dispatch({
        type: SET_AUTH_LOADING,
        payload: false,
    });

    const proData = await axios.get(`/api/v1/pro`, {
        withCredentials: true,
    });

    const payload = {
        user: proData.data,
        pro: proData.data.pro,
    };

    setCookie('user-status', 'pro');
    localStorage.setItem('User', payload?(payload.user.firstName):null);
    dispatch({
        type: USER_LOADED,
        payload,
    });

    const proLeads = await axios.get(`/api/v1/order/pro-leads?id=${localStorage.getItem('proid')}`, {
        withCredentials: true,
    });

    dispatch({
        type: LEADS_LOADED,
        payload: proLeads.data,
    });

    Router.replace('/pro/profile');
};

export const login = ({
    number,
    countryCode,
    password,
    reload = false,
}) => async (dispatch) => {
    if (!password) {
        return dispatch({
            type: AUTH_ERROR,
            payload: ['please add a password.'],
        });
    }

    dispatch({
        type: SET_AUTH_LOADING,
        payload: true,
    });

    let data;

    try {
        data = await axios({
            method: 'post',
            url: process.env.BACKEND_URL + '/api/v1/auth/login',
            withCredentials: true,
            data: {
                countryCode,
                phone: number,
                password,
            },
        });

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });
        if (data.data.status === 'deactivate') {
            return  dispatch({
                type: AUTH_ERROR,
                payload: ['Account is Deactivated'],
            });
        }
        if (data.data.status === 'pro') {
            setCookie('user-status', 'pro');
            localStorage.setItem('proid',data.data.pro.id);
            const proData = await axios.get(`/api/v1/pro`, {
                withCredentials: true,
            });

            const pro = proData.data.pro;


            delete proData.data.pro;
            localStorage.setItem('User', proData.data?(proData.data.firstName):null);
            localStorage.setItem('userId', null);
            dispatch({
                type: USER_LOADED,
                payload: {
                    user: proData.data,
                    pro,
                },
            });

            const proLeads = await axios.get(`/api/v1/order/pro-leads?id=${localStorage.getItem('proid')}`, {
                withCredentials: true,
            });

            dispatch({
                type: LEADS_LOADED,
                payload: proLeads.data,
            });

            if (reload) {
                Router.reload();
            } else {
                Router.replace('/pro/leads');
            }
        } else {
            const { data } = await axios.get(
                process.env.BACKEND_URL + '/api/v1/auth/login',
                {
                    withCredentials: true,
                }
            );
            localStorage.setItem('userId',data?data.id:null);
            localStorage.setItem('User', data?(data.firstName):null);
            dispatch({
                type: USER_LOADED,
                payload: {
                    user: data,
                },
            });

            if (reload) {
                Router.reload();
            } else {
                Router.replace('/');
            }
        }
    } catch (err) {
        const { response } = err;

        const errors = response.data || [];

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });

        dispatch({
            type: REGISTER_FAIL,
            payload: errors,
        });
        throw err;
    }
};

export const confirmProfessional = ({
    phone,
    code,
    password,
    countryCode,
    redirectUrl,
}) => async (dispatch) => {
    try {
        dispatch({
            type: SET_AUTH_LOADING,
            payload: true,
        });

        await axios({
            method: 'post',
            url: process.env.BACKEND_URL + '/api/v1/pro/confirm',
            withCredentials: true,
            data: {
                phone,
                code,
                password,
                countryCode,
            },
        });

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });

        const proData = await axios.get(`/api/v1/pro`, {
            withCredentials: true,
        });

        const payload = {
            user: proData.data,
            pro: proData.data.pro,
        };

        setCookie('user-status', 'pro');
        localStorage.setItem('User', payload?(payload.user.firstName):null);
        dispatch({
            type: USER_LOADED,
            payload,
        });

        const proLeads = await axios.get(`/api/v1/order/pro-leads?id=${localStorage.getItem('proid')}`, {
            withCredentials: true,
        });

        dispatch({
            type: LEADS_LOADED,
            payload: proLeads.data,
        });
        if(redirectUrl){
            await Router.replace(redirectUrl);
        }
    } catch (err) {
        const { response } = err;

        const errors = response.data || [];

        dispatch({
            type: SET_AUTH_LOADING,
            payload: false,
        });

        dispatch({
            type: REGISTER_FAIL,
            payload: errors,
        });
        throw err;
    }
};