/* eslint-disable no-case-declarations */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from '../actions/types';

const initialState = {
    loading: false,
    messages: null,
    unreadMessages: 0,
    message: {},
    loadingMessage: false,
};

export const messages = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.messages,
            };
        case types.MESSAGES_LOADED:
            const lastMessages = payload.map((chat) => chat.messages.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]);

            const unread = lastMessages.filter((msg) => msg && msg.unread_user);

            return {
                ...state,
                loading: false,
                unreadMessages: unread.length,
                messages: payload,
            };
        case types.MESSAGE_LOADED:


            return {
                ...state,
                loading: false,

                messages: payload,
            };
        case types.UPDATE_UNREAD_MESSAGES_NUMBER:
            return {
                ...state,
                unreadMessages: payload,
            };
        case types.TRASH_CONVERSATION:
            const { chatId, messages } = payload;

            if (messages && messages.length > 0) {
                const chatIndex = messages.findIndex((c) => c.id === parseInt(chatId));
                if (chatId !== -1) {
                    messages.splice(chatIndex, 1);
                    return {
                        ...state,
                        messages,
                    };
                }
            }

            return {
                ...state,
            };
        case types.MESSAGES_LOADED_PRO:
            const lastMessagesPro = payload.map((chat) => chat.messages.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]);
            const unreadPro = lastMessagesPro.filter((msg) => msg && msg.unread_pro);

            return {
                ...state,
                loading: false,
                unreadMessages: unreadPro.length,
                messages: payload,
            };
        case types.GET_USER_MESSAGES:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
