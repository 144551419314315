import { HYDRATE } from 'next-redux-wrapper';
import * as types from '../actions/types';

const initialState = {
    loginForm: false,
    signupForm: false,
};

export const modals = (state = initialState, action) => {
    const {payload, type} = action;

    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.modals,
            };
        case types.TOGGLE_MODAL:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};
