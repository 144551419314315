import { HYDRATE } from 'next-redux-wrapper';
import { QUESTIONS_LOADED } from '../actions/types';

const initialState = {
    serviceId: null,
    questions: null,
};

export const questions = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.questions,
            };
        case QUESTIONS_LOADED:
            return {
                ...payload,
            };

        default:
            return state;
    }
};
