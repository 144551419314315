interface ApiError {
    msg: string;
    param: string;
    errorCode: string;
}

export interface ApiErrorResponse {
    response: {
        data: {
            errors: ApiError[];
        }
    }
}

export interface LocalizationTexts { eng: string; ru: string; uz: string }

export interface Pro {
    id: number;
    name?: string;
    is_verified: boolean;
    is_guarantee_provided: boolean | null;
    overview_hired: string;
    overview_confirmed: boolean;
    overview_employees_count: number | null;
    overview_year_founded: number | null;
    overview_phone: string;
    overview_website: string;
    overview_address?: string[];
    description: string;
    profile_image: string;
    work_images: string[] | null;
    workImages: string[] | null;
    payments: string[];
    pricing_display: boolean;
    pricing_contact: boolean;
    pricing_manual_hourly_price: string;
    pricing_manual_estimated: boolean;
    pricing_manual_starting: boolean;
    social_media_telegram: string;
    social_media_facebook: string;
    social_media_instagram: string;
    review_average: number;
    hide_pro: boolean;
    work_time: string;
}

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    tgusername: string;
    phone_validated: boolean;
    status: 'customer' | 'pro';
    imageUrl: string;
    isAdmin: string;
    isPro: string;
}

export interface Review {
    id: number;
    respond: string;
    rate: number;
    comment: string;
    userId: string;
    proId: string;
    serviceId: string;
    createdAt: string;
}

export interface Service {
    id: number;
    titleUZ: string;
    titleRU: string;
    titleEN: string;
    imageUrl: string;
    main: boolean;
    keywords: string[];
    order: number;
    populars: boolean;
    benefits: boolean;
    AvgCost: string;
}

export interface ProService {
    id: number;
    work_time: string[] | null;
    createdAt: string;
    pricing_display: boolean;
    pricing_manual_average: null;
    pricing_manual_hourly_price: null;
    pricing_manual_starting: null;
    proId: number;
    serviceId: number;
    updatedAt: string;
}

export interface ClassNamed {
    className?: string;
}

export interface Clickable {
    onClick(): void;
}

export interface ChoiceOrQuestionText extends LocalizationTexts {
    belongs: 'pro' | 'client' | 'user';
    choice_image: string | null;
    createdAt: string;
    group_id: number;
    id: number;
    multiple: boolean;
    proChoiceId: null
    questionId: number;
    show_images: boolean;
    type: 'choice' | 'question';
    updatedAt: string;
    order: number | null;
}
export enum QuestionType {
    choice = 'choice',
    question = 'question',
}
export interface DescriptionText {
    id: number;
    eng: string;
    ru: string;
    uz: string;
}

export interface Question {
    choiceText: ChoiceOrQuestionText[];
    client_only: boolean
    createdAt: string;
    descriptionId: number | null;
    descriptionText: DescriptionText[];
    filter_show: boolean;
    general: boolean;
    id: number;
    input_bar: boolean;
    is_template: boolean;
    order: number;
    pro_page: boolean;
    questionText: ChoiceOrQuestionText[];
    serviceId: number;
    skip: boolean;
    type: QuestionType;
    updatedAt: string;
}

export interface VerificationDocument {
    id: number;
    url: string;
    name: string;
    type: string;
}