/* eslint-disable no-case-declarations */
import * as types from '../actions/types';
import moment from 'moment';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
    leads: null,
    unreadLeads: 0,
};

export const leads = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.leads,
            };
        case types.LEADS_LOADED:
            const newLeads = payload.length>0&&payload.filter(
                (l) => l.proleads.unread && moment().isBefore(l.order.expiry_date)
            );

            return {
                ...state,
                unreadLeads: newLeads.length,
                leads: payload,
            };
        default:
            return state;
    }
};
