import { HYDRATE } from 'next-redux-wrapper';
import * as types from '../actions/types';

const initialState = {
    lang: 'ru',
    showMobileFooter: true,
    showFooter: true,
    stickyNav: false,
    searchbar:false,
};

export const settings = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.settings,
            };
        case types.CHANGE_LANG:
            return {
                ...state,
                lang: payload,
            };
        case types.TOGGLE_SHOW_MOBILE_FOOTER:
            return {
                ...state,
                showMobileFooter: payload,
            };
        case types.TOGGLE_SHOW_FOOTER:
            return {
                ...state,
                showFooter: payload,
            };
        case types.TOGGLE_STICKY_NAV:
            return {
                ...state,
                stickyNav: payload,
            };
        case types.TOGGLE_SEARCHBAR:
            return {
                ...state,
                searchbar: payload,
            };
        default:
            return state;
    }
};
