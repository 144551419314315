export const CHANGE_LANG = 'CHANGE_LANG';
export const ACCOUNT ='ACCOUNT';
export const NOTIFICATION='NOTIFICATION';
export const PAYMENTS ='PAYMENTS';
export const QUOTES = 'QUOTES';
export const BACKSET = 'BACKSET';
export const DESC = 'DESC';


export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_ORDER_POPUP_ANSWERS = 'SET_ORDER_POPUP_ANSWERS';
export const TOGGLE_ORDER_POPUP_VISIBILITY = 'TOGGLE_ORDER_POPUP_VISIBILITY';
export const TOGGLE_ACTIVITY_DETAILS_POPUP_VISIBILITY =
  'TOGGLE_ACTIVITY_DETAILS_POPUP_VISIBILITY';
export const TOGGLE_ADD_SERVICE_POPUP_VISIBILITY =
  'TOGGLE_ADD_SERVICE_POPUP_VISIBILITY';
export const TOGGLE_SHOW_MOBILE_FOOTER = 'TOGGLE_SHOW_MOBILE_FOOTER';
export const MESSAGES_LOADED = 'MESSAGES_LOADED';
export const MESSAGE_LOADED = 'MESSAGE_LOADED';
export const MESSAGES_LOADED_PRO = 'MESSAGES_LOADED_PRO';
export const GET_USER_MESSAGES = 'GET_USER_MESSAGES';
export const SET_ORDER_POPUP_CALENDAR = 'SET_ORDER_POPUP_CALENDAR';
export const TOGGLE_MESSAGES_INFO_POPUP_VISIBILITY =
  'TOGGLE_MESSAGES_INFO_POPUP_VISIBILITY';
export const TOGGLE_SHOW_FOOTER = 'TOGGLE_SHOW_FOOTER';
export const UPDATE_PRO_COINS = 'UPDATE_PRO_COINS';
export const SWITCH_TO_PRO = 'SWITCH_TO_PRO';
export const SWITCH_TO_CLIENT = 'SWITCH_TO_CLIENT';
export const ADD_NEW_PRO_TEMPLATE = 'ADD_NEW_PRO_TEMPLATE';
export const UPDATE_UNREAD_MESSAGES_NUMBER = 'UPDATE_UNREAD_MESSAGES_NUMBER';
export const TRASH_CONVERSATION = 'TRASH_CONVERSATION';
export const LEADS_LOADED = 'LEADS_LOADED';
export const QUESTIONS_LOADED = 'QUESTIONS_LOADED';
export const TOGGLE_STICKY_NAV = 'TOGGLE_STICKY_NAV';
export const TOGGLE_SEARCHBAR = 'TOGGLE_SEARCHBAR';
export const TOGGLE_PRO_INBOX_DETAILS_POPUP_VISIBILITY =
  'TOGGLE_PRO_INBOX_DETAILS_POPUP_VISIBILITY';
export const TICK = 'TICK';
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const RESET = 'RESET';