import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    margin: 40px auto 40px;
    max-width: 780px;
    flex-direction: column;
    text-align: center;
`;

class ErrorBoundary extends Component<unknown, { hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container>
                    <h3>Something went wrong.</h3>
                    <p>Please, refresh a page. If that does not help try logging out and logging in again.</p>
                </Container>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;