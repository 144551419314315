import axios from '../utils/axios';
import {
    VALIDATE_PHONE_NUMBER,
    RESEND_VALIDATION_CODE,
    SEND_PASSWORD_RESET_CODE,
    SEND_ACCOUNT_CONFIRMATION_CODE,
} from './routes';

interface ValidateNumberAndSignUpArgs {
    phone: number;
    code: string;
    password: string;
    countryCode: string;
    isPro?: boolean;
}

export const validateNumberAndSignUp = ({ phone, code, password, countryCode, isPro }: ValidateNumberAndSignUpArgs) => axios({
    method: 'post',
    url: process.env.BACKEND_URL + VALIDATE_PHONE_NUMBER,
    withCredentials: true,
    data: {
        phone,
        countryCode,
        code,
        password,
        isPro,
    },
});

interface PhoneCountryCodeArgs {
    phone: number;
    countryCode: string;
}

interface ResendValidationCodeArgs extends PhoneCountryCodeArgs {
    isPro?: boolean;
}

export const resendValidationCode = ({ phone, countryCode, isPro }: ResendValidationCodeArgs) => axios({
    method: 'post',
    url: process.env.BACKEND_URL + RESEND_VALIDATION_CODE,
    withCredentials: true,
    data: {
        phone,
        countryCode,
        isPro,
    }
});

export const sendPasswordResetCode = ({ phone, countryCode }: PhoneCountryCodeArgs) => axios({
    method: 'post',
    url: process.env.BACKEND_URL + SEND_PASSWORD_RESET_CODE,
    data: {
        phone,
        countryCode,
    },
});

export const confirmAccount = ({ phone, countryCode }: PhoneCountryCodeArgs) => axios({
    method: 'post',
    url: process.env.BACKEND_URL + SEND_ACCOUNT_CONFIRMATION_CODE,
    data: {
        phone,
        countryCode,
    },
});
