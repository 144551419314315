export const theme = {
    colors: {
        indigo: 'var(--color-indigo)',
        frenchViolet: 'var(--color-french-violet)',
        jet: 'var(--color-jet)',
        flickrBlue: 'var(--color-flickr-blue)',
        greenPantone: 'var(--color-green-pantone)',
        red: 'var(--color-red)',
        redShade: 'var(--color-red-shade)',
        flickrBlueShade: 'var(--color-flickr-blue-shade)',
    },
};
