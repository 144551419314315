
import { HYDRATE } from 'next-redux-wrapper';
import * as types from '../actions/types';

const initialState = {
    account : false,
    payments: false,
    notifications: false,
    quotes: false,
    back: true
};

export const profile = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case HYDRATE:
            return {
                ...state,
                ...payload.profile,
            };
        case types.ACCOUNT:
            return {
                ...state,
                account: true,
                notifications: false,
                back:false
            };
        case types.NOTIFICATION:
            return {
                ...state,
                account:false,
                notifications: true,
                back:false
            };
        case types.PAYMENTS:
            return {
                ...state,
                payments: true,
                back:false
            };
        case types.QUOTES:
            return {
                ...state,
                quotes: true,
                back:false
            };
        case types.BACKSET:
            return {

                quotes: false,
                payments: false,
                account: false,
                notifications: false,
                back: true,
            };
        default:
            return state;
    }
};
