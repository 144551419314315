import { Popover} from 'antd';
import { useState } from 'react';
import CustomSteps from './Steps';
import { CloseOutlined } from '@ant-design/icons';
import axios from '../../utils/axios';
import { useSelector } from 'react-redux';


const PopOverCustom =({children,placement,visible,id,myfunctions,lead,screenWidth,message,service,main})=>{

    const[open,setOpen]=useState(false);
    const idss = useSelector((state) => state.tooltip.counter);

    const user = useSelector((state) => state.auth.user);

    let styles;
    switch (placement) {
        case 'rightTop':
            styles=   {position:'absolute',top:'5px',left:'-38px' ,filter: 'drop-shadow(0 0 36px gray)',opacity:'0.8'};
            break;
        case 'right':
            styles=   {position:'absolute',top:'40%',left:'-38px', filter: 'drop-shadow(0 0 36px gray)'};
            break;
        case 'rightBottom':
            styles=   {position:'absolute',top:'5px',left:'-38px'};
            break;
        case 'left':
            styles=   {position:'absolute',top:'30px',right:'-30px',transform:'rotate(180deg)'};
            break;
        case 'leftTop':
            styles=   {position:'absolute',top:service?'15px':'30px',right:'-30px',transform:'rotate(180deg)'};
            break;
        case 'bottom':
            styles=   {position:'absolute',top:screenWidth<1024 &&! message?'-9px':screenWidth<1024 &&message?'-19px': '4px',left:service?'70%':'42%', transform: screenWidth<1024?'': 'rotate(90deg)'};
            break;
        case 'bottomRight':
            styles=   {position:'absolute',top:screenWidth<1024?'-9px': '4px',left:'42%', transform: screenWidth<1024?'': 'rotate(90deg)'};
            break;
        case 'bottomLeft':
            styles=   {position:'absolute',top:'-9px',left:'9%'};
            break;
        case 'top':
            styles=  service?{position:'absolute',top:'78%',left:screenWidth>1024?'42%':'10%',transform:screenWidth<1024?'rotate(180deg)': 'rotate(270deg)'}: {position:'absolute',top:screenWidth<1024&&idss!==3?'94%':screenWidth<1024&&idss==3?'87%': '4px',left:'42%', transform: screenWidth<1024?'rotate(180deg)': 'rotate(90deg)'};
            break;
        case 'topRight':
            styles=   {position:'absolute',top:screenWidth<1024&&idss!==3?'94%':screenWidth<1024&&idss==3?'87%': '4px',left:'42%', transform: screenWidth<1024?'rotate(180deg)': 'rotate(90deg)'};
            break;
        case 'topLeft':
            styles={display:'none'};
    }

    const myfunction=(arg)=>{
        myfunctions(arg);
        if(arg==-1){
            setOpen(true);
        }
    };
    const setTool=(args)=>{
        axios.put(`api/v1/user/tooltip/${user.id}`,{tooltip:args});
    };
    return(

        <>
            {lead&&screenWidth>1024&&
  <>
      {/* {!open && visible&&
  <div style={{display:user?.tooltip?.includes("lead2")&&"none"}}class="ant-modal-mask"></div>} */}
      {!user?.tooltip?.includes('lead2')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('lead2');}} style={{color:'#d1d1d1'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-6.77526e-07 14.5L50 -2.78221e-07L50 30L-6.77526e-07 14.5Z" fill="white"/>
          </svg> <CustomSteps lead={lead} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<>
              {children}
          </>
      }
  </>}
            {message&&screenWidth<1024&&
  <>
      {/* {!open && visible&&
  <div style={{display:user?.tooltip?.includes("lead2")&&"none"}}class="ant-modal-mask"></div>} */}
      {!user?.tooltip?.includes('msg2')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('msg2');}} style={{color:'#d1d1d1'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 0L30 30L0 30L15.5 0Z" fill={placement=='top'?'white':'#DDDFFF'}/>
          </svg> <CustomSteps message={true} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<>
              {children}
          </>
      }
  </>}
            {service&&screenWidth<1024&&
  <>
      {/* {!open && visible&&
  <div style={{display:user?.tooltip?.includes("lead2")&&"none"}}class="ant-modal-mask"></div>} */}
      {!user?.tooltip?.includes('serv2')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('serv2');}} style={{color:'#d1d1d1'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 0L30 30L0 30L15.5 0Z" fill={placement=='top'?'#fafaf9':'#DDDFFF'}/>
          </svg> <CustomSteps service={true} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<>
              {children}
          </>
      }
  </>}
            {message&&screenWidth>1024&&
  <>
      {/* {!open && visible&&
  <div style={{display:user?.tooltip?.includes("lead2")&&"none"}}class="ant-modal-mask"></div>} */}
      {!user?.tooltip?.includes('msg2')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('msg2');}} style={{color:'#d1d1d1'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-6.77526e-07 14.5L50 -2.78221e-07L50 30L-6.77526e-07 14.5Z" fill="white"/>
          </svg>
          <CustomSteps message={true} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<>
              {children}
          </>
      }
  </>}
            {service&&screenWidth>1024&&
  <>
      {/* {!open && visible&&
  <div style={{display:user?.tooltip?.includes("lead2")&&"none"}}class="ant-modal-mask"></div>} */}
      {!user?.tooltip?.includes('serv2')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('serv2');}} style={{color:'#d1d1d1'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-6.77526e-07 14.5L50 -2.78221e-07L50 30L-6.77526e-07 14.5Z"fill={placement=='top'?'#fafaf9':'white'}/>
          </svg>
          <CustomSteps service={true} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<>
              {children}
          </>
      }
  </>}

            {lead&&screenWidth<1024&&
  <>
      {/* {!open && visible&&
  <div style={{display:user?.tooltip?.includes("lead2")&&"none"}}class="ant-modal-mask"></div>} */}
      {!user?.tooltip?.includes('lead2')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('lead2');}} style={{color:'#d1d1d1'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 0L30 30L0 30L15.5 0Z" fill={placement=='top'?'white':'#DDDFFF'}/>
          </svg> <CustomSteps lead={true} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<>
              {children}
          </>
      }
  </>}
            {main&& screenWidth>1024&&
  <>
      {/* {!open && visible&&
  <div class="ant-modal-mask" style={{display:user?.tooltip?.includes("lead1")&&"none"}}></div>} */}
      {  !user?.tooltip?.includes('lead1')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('lead1');}} style={{color:'#d1d1d1'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-6.77526e-07 14.5L50 -2.78221e-07L50 30L-6.77526e-07 14.5Z" fill="white"/>
          </svg> <CustomSteps lead={false} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<div>
              {children}</div>}
  </>

            }

            {main&& screenWidth<1024&&
  <>
      {/* {!open && visible&&
  <div class="ant-modal-mask" style={{display:user?.tooltip?.includes("lead1")&&"none"}}></div>} */}
      {  !user?.tooltip?.includes('lead1')?
          <Popover placement={placement} title={<><span style={{float:'right'}}><CloseOutlined onClick={()=>{setOpen(true); myfunctions(-1); setTool('lead1');}} style={{color:'#888888'}} /></span><span style={{ fontWeight:'600', fontSize:'15px',textAlign:'left'}}></span></>} visible={!open?visible:false} content={<div>  <svg style={styles} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 0L30 30L0 30L15.5 0Z"  fill={placement=='top'?'white':'#DDDFFF'}/>
          </svg>
          <CustomSteps screenWidth={screenWidth} lead={false} myfunction ={(as)=>{myfunction(as);}} id ={id}/></div>}>


              {children}
          </Popover>:<div>
              {children}</div>}
  </>

            }
        </>
    );};

export default PopOverCustom;