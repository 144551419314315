import axios, { CancelToken } from 'axios';

const instance = axios.create({
    baseURL: process.env.BACKEND_URL,
    credentials: true,
    withCredentials: true,
});

instance.CancelToken = CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
