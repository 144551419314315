/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Link from 'next/link';
import PopOverCustom from '../components/PopOver/PopOver';

export const AddPhotosInPortfolio = (lang,ids) => {

    if (lang === 'eng') {

        return (
            <>
                <Link href="/pro/profile/edit">Add photos</Link> in your job portfolio

                <PopOverCustom  myfunctions={()=>{return null;}}  visible={ids==3?true:false} id={3} placement={'bottom'} title={'Title 03'}><Link  href="/pro/profile/edit">describe your business</Link> </PopOverCustom> to
        catch more attention.
            </>
        );
    }
    if (lang === 'ru') {
        return (
            <>
                <PopOverCustom  myfunctions={()=>{return null;}} visible={ids==3?true:false} id={3} placement={'bottom'} title={'Title 03'}> <Link  href="/pro/profile/edit">Добавьте фотографии</Link></PopOverCustom> в свое
        портфолио и опишите свой бизнес, чтобы привлечь больше внимания.
            </>
        );
    }
    if (lang === 'uz') {
        return (
            <>
        Ish portfelingizga{' '}
                <Link  href="/pro/profile/edit">fotosuratlar qo'shing</Link>  va e'tiborni
        jalb qilish uchun ishingiz haqida
                <PopOverCustom  myfunctions={()=>{return null;}} visible={ids==3?true:false} id={3} placement={'bottom'} title={'Title 03'}>    <Link   href="/pro/profile/edit">batafsil ma'lumotlar yozing.</Link>
                </PopOverCustom>
            </>
        );
    }
};

export const Lead = {
    eng: 'Lead',
    ru: 'Клиент',
    uz: 'Mijoz',
};

export const Edit = {
    eng: 'Edit',
    ru: 'Изменить',
    uz: 'Õzgartirish',
};

export const Agreement = {
    eng: 'Agreement',
    ru: 'Соглашение',
    uz: 'Shartnoma',
};

export const Request_received = {
    eng: 'Thank you! Your request received.',
    ru: 'Спасибо! Ваш запрос получен.',
    uz: 'Rahmat! Sizning so\'rovingiz qabul qilindi.',
};

export const You_will_be_notified = {
    eng: 'You will be notified if your request matches the right specialist.',
    ru:
    'Вы получите уведомление как только найдется подходящий специалист по вашему запросу.',
    uz: 'So\'rovingiz uchun mos mutaxassis topilishi bilan sizga xabar beramiz.',
};

export const How_we_match_jobs = {
    eng: 'Read on how we match jobs and pros »',
    ru: 'Узнайте, как мы подбираем специалиста для клиентов »',
    uz: 'So’rovlarga qanday qilib mutaxassis tanlashimiz haqida »',
};

export const My_jobs = {
    eng: 'MY JOBS',
    ru: 'МОИ ПРОЕКТЫ',
    uz: 'МОИ ПРОЕКТЫ',
};

export const See_all_in_category = {
    eng: 'See all in category',
    ru: 'Смотреть всё в категории',
    uz: 'Hammasini kategoriyada ko\'rish',
};

export const sd1={

    eng:' You have an option to receive the exact orders you want. For that, you need to specify the exact services you provide. It will help you to filter out the clients that you don\'t want. Also, it helps customers to find the specialists they want. It will save time and money on both sides.\
<br><b>Reminder</b>: if you want more clients, show them good service and respect. They will leave a positive feedback and you may become TOP specialist, for free.',

    ru: 'У вас есть возможность получать именно те заказы, которые вы хотите. Для этого вам необходимо указать, какие именно услуги вы предоставляете. Это поможет вам отфильтровать нежелательных клиентов. Кроме того, клиенты могут найти нужных им специалистов. Обе стороны сэкономят время и деньги.\
<br><b>Запомните</b>: если вы хотите больше клиентов, проявите к ним хорошее обслуживание и уважение. Они оставят хорошие отзывы, и вы станете TOP-специалистом, бесплатно.',

    uz:  'O\'zingiz xohlagan buyurtmalarni olish imkoniyatiga egasiz. Buning uchun siz ko\'rsatadigan xizmatlarni aniq belgilashingiz shart. Bu bilan siz istagan mijozlar soni ko\'payishiga yo\'l ochasiz. Sizga kerak bo\'lmagan mijozlarni filtrlashga yordam beradi. Shuningdek, mijozlarga kerakli mutaxassislarni topishga yordam beradi. Bu har ikki tomondan ham vaqt va pulni tejaydi.\
<br><b>Eslatma</b>: agar siz ko\'proq mijozlarni xohlasangiz, ularga yaxshi xizmat va hurmat ko\'rsating. Ular yaxshi fikr qoldiradilar va siz tekinga TOP\'ga chiqasiz.'

};

export const sd2={
    eng: 'Indicate the districts you work so the clients can find you faster in case if they need quick help. If your service covers the entire city, make sure to select all districts. You can change and add more districts later.',

    ru: 'Укажите районы, в которых вы работаете, чтобы клиенты могли быстрее вас найти в случае, если им понадобится быстрая помощь. Если ваша услуга охватывает весь город, обязательно выберите все районы. Вы можете изменить и добавить другие районы позже.',

    uz: 'Siz faoliyat kórsatadigan tumanlarni belgilang. Bu bilan, atrofingizdagi mijozlarga tezroq xizmat kórsatish imkoni bóladi. Agar xizmatingiz butun shaharni qamrab olsa, barcha tumanlarni tanlang. Manzilni ózgartirish va keyinchalik qo\'shimcha tumanlar kiritish imkoni ham bor.'
};



export const sd3={
    eng: 'Optionally, you can add pricing for each service you provide. This is the best way to save time and filter out desired customers. Don\'t be afraid to publish service pricing. Show them awesome service instead of lowering your pricing, and they will reward you properly.',

    ru: 'Вы можете добавить цены на каждую предоставляемую услугу. Это лучший способ сэкономить время и отфильтровать желаемых клиентов. Не бойтесь публиковать расценки на услуги. Покажите им отличный сервис вместо того, чтобы снижать цены, и клиенты вознаградят вас должным образом.',

    uz: 'Siz taqdim etayotgan har bir xizmat uchun narxlarni ko\'rsatishingiz mumkin. Bu – vaqtni tejash va mijozlarni filtrlashning eng yaxshi usulidir. Narxlarni omma oldiga qo\'yishga cho\'chimang. Narxlaringizni pasaytirish o\'rniga mijozlarga ajoyib xizmatni ko\'rsating, shunda ular sizga munosib to\'lov qilishadi.'
};

export const Prevs={
    eng: 'Previous',
    ru: 'Предыдущий',
    uz: 'Oldingi',
};
export const Nexts={
    eng: 'Next',
    ru: 'Следующий',
    uz: 'Keyingisi'
};
export const Finish={
    eng: 'Finish',
    ru: 'Завершить',
    uz: 'Tugatish'
};
export const serviceDesc={
    eng: 'Choose service types',
    ru: 'Выберите виды работ',
    uz: 'Ish turlarini tanlang'
};
export const Leads = {
    eng: 'Leads',
    ru: 'Клиенты',
    uz: 'Mijozlar',
};

export const Messages = {
    eng: 'Messages',
    ru: 'Сообщения',
    uz: 'Xabarlar',
};

export const No_Messages = {
    eng: 'No Messages yet',
    ru: 'Нет сообщений',
    uz: 'Xabarlar yo\'q',
};

export const Services = {
    eng: 'Services',
    ru: 'Услуги',
    uz: 'Xizmatlar',
};
export const legalName={
    eng: 'Your legal name',
    ru: 'Ваше официальное имя',
    uz: 'Sizning qonuniy ismingiz',
};

export const AddS={
    eng: 'Add Service',
    ru: 'Добавить услугу',
    uz: 'Xizmatni qo\'shish'
};
export const BackS={
    eng: 'Back',
    ru: 'Назад',
    uz: 'Ortga'
};
export const fileNotSupportd={
    eng: 'File format not supported. Supported formats .jpg .jpeg and .png',
    ru: 'Формат файла не поддерживается. Поддерживаемые форматы .jpg .jpeg и .png.',
    uz: 'Faylingizni yuklab bo’lmaydi. Qabul qilingan formatlar .jpg .jpeg va .png'
};
export const SizeE={


    eng: 'File size must be under 5mb. Please use another picture or decrease it before upload.',
    ru: 'Размер файла не должен превышать 5 МБ. Пожалуйста, используйте другое изображение или уменьшите его перед загрузкой.',
    uz: 'Fayl hajmi 5 MB dan oshmasligi kerak. Yuklashdan oldin fayl hajmini qisqartiring yoki boshqa rasmni ishlating. (edited)',
};

export const LoginNum={
    eng: 'This number will be used as a login',
    ru: 'Этот номер будет использоваться как логин',
    uz: 'Ushbu raqam akkauntga kirishda ishlatiladi'
};
export const Preferred_service_date = {
    eng: 'Preferred service date',
    ru: 'Желаемая дата',
    uz: 'Xizmat kuni',
};

export const order_received_date = {
    eng: 'order received date',
    ru: 'дата получения заказа',
    uz: 'buyurtma olingan sana',
};

export const Send = {
    eng: 'Send',
    ru: 'Отправить',
    uz: 'Yuborish',
};

export const Save_the_template = {
    eng: 'Save the template',
    ru: 'Сохранить шаблон',
    uz: 'Shablonni saqlash',
};

export const Save_as_a_template = {
    eng: 'Save as a template',
    ru: 'Сохранить как шаблон',
    uz: 'Shablon sifatida saqla',
};

export const Template_successfully_saved = {
    eng: 'Template successfully saved',
    ru: 'Шаблон успешно сохранен',
    uz: 'Shablon saqlandi',
};

export const Send_and_Save = {
    eng: 'Send and Save',
    ru: 'Отправить и Сохранить',
    uz: 'Yubor va Saqla',
};

export const Profile = {
    eng: 'Profile',
    ru: 'Профиль',
    uz: 'Profil',
};
export const ProAttach = {
    eng: 'Attachments',
    ru: 'Фото и файлы',
    uz: 'Rasm va fayllar'
};
export const AddAttachment ={
    eng: 'Add attachment',
    ru: 'Загрузить фото или файл',
    uz: 'Rasm yoki fayl yuklash'
};
export const Profile_owner = {
    eng: 'Profile Owner',
    ru: 'Владелец Профиля',
    uz: 'Profil Egasi',
};

export const Business_owner = {
    eng: 'Business Owner',
    ru: 'Владелец Бизнеса',
    uz: 'Biznes egasi',
};
export const BuisnessPhone ={
    eng: 'Business Phone',
    ru: 'бизнес телефон',
    uz: 'Biznes Telefon',

};
export const BuisnessDesc ={
    eng: 'Business Description',
    ru: 'О вашем сервисе',
    uz: 'Xizmatingiz haqida ma\'lumot',



};

export const NOUNread={
    eng: 'There are no unread messages',
    ru: 'Нет непрочитанных сообщений',
    uz: 'O\'qilmagan xabarlar yo\'q'
};

export const AddService={
    eng: 'Add a Service',
    ru:' Добавить услугу',
    uz: 'Xizmatni qo\'shish'
};

export const PricinGdEsc= {
    eng: 'Create convenience for the customers by specifying the price of your services.',
    ru: 'Дарите удобство для клиентов , указав цену своей услуги.',
    uz: 'Mijozlar bilan ishlashda qulayroq bo\'lishi uchun xizmatingiz  narxini ko\'rsating.'
};

export const JObTYpeDEsc={
    eng: 'Choose the service type you offer and we will send relevant customers.',
    ru: 'Выберите тип услуги, который вы предлагаете, и мы отправим соответствующих клиентов.',
    uz: 'Siz taklif qilayotgan xizmat turini tanlang va biz tegishli mijozlarni yuboramiz.'
};

export const CatOp={

    eng: 'Category (Optional):',
    ru: 'Категория (необязательно):',
    uz: 'Kategoriya (ixtiyoriy):'
};

export const SNAMe={
    eng:' Service Name',
    ru:' Наименование сервиса',
    uz:' Xizmat nomi'
};
export const Onedist={
    eng: 'Please, select at least 1 district',
    ru: 'Пожалуйста, выберите хотя бы 1 район',
    uz: 'Iltimos, kamida 1 ta tumanni tanlang'

};
export const NOMSG={
    eng: 'There are no messages as of now.',
    ru: 'На данный момент сообщений нет.',
    uz: 'Hozircha hech qanday xabar yo\'q.'
};
export const Pricing = {
    eng: 'Pricing',
    ru: 'Ценообразование',
    uz: 'Narxlar',
};

export const Do_not_display_price_information = {
    eng: 'Do not display price information',
    ru: 'Не отображать информацию о ценах',
    uz: 'Narxlar haqida ma\'lumotni ko\'rsatma',
};

export const Let_people_contact_for_price = {
    eng: 'Let people contact for price',
    ru: 'Позвольте людям связаться по цене',
    uz: 'Mijozlar narx bo\'yicha murojaat qilsin',
};

export const Set_as_customer = {
    eng: 'Set as a customer',
    ru: 'Войти как клиент',
    uz: 'Mijoz bo\'lib kirish',
};

export const Enter_pricing_manually = {
    eng: 'Enter pricing manually',
    ru: 'Введите цены вручную',
    uz: 'Narxlarni qo\'lda kiriting',
};

export const Hourly_cost = {
    eng: 'Hourly cost',
    ru: 'Почасовая оплата',
    uz: 'Soatbay narxi',
};

export const Customer_profile = {
    eng: 'Customer profile',
    ru: 'Профиль пользователя',
    uz: 'Mijoz profili',
};

export const Your_account = {
    eng: 'Your account',
    ru: 'Ваш аккаунт',
    uz: 'Sizning akkauntingiz',
};

export const Set_as_a_pro = {
    eng: 'Switch to Pro',
    ru: 'Перейти к профилю специалиста',
    uz: 'Mutaxassis profiliga ótish',

};

export const Logout = {
    eng: 'Logout',
    ru: 'Выйти',
    uz: 'Chiqish',
};

export const AvgCost={
    eng: 'Avg. cost',
    ru:' Средняя цена',
    uz: 'Órtacha narx'
};

export const Find_the_perfect_professional_for_you = {
    eng: 'Find the right <span>professional</span> for you',
    ru: 'Найдите нужного <span>специалиста</span> для себя',
    uz: 'Eng zo\'r <span>professionalni</span> toping',
};

export const Pro_brief_message = {
    eng: 'Tell this pro briefly what you’re looking for.',
    ru: 'Что нужно сделать?',
    uz: 'Nima qilish kerak?',
};

export const Select = {
    eng: 'Select',
    ru: 'Выбрать',
    uz: 'Tanlang',
};

export const Select_Placeholder = {
    eng: 'Select',
    ru: 'Выбрать',
    uz: 'Tanlash',
};

export const Search_service = {
    eng: 'Enter the service name',
    ru: 'Введите название услуги',
    uz: 'Xizmat nomini kiriting',
};

export const Search = {
    eng: 'Search',
    ru: 'Поиск',
    uz: 'Qidirish',
};

export const Popular_Service_in_Your_Area = {
    eng: 'Popular Service in Your Area',
    ru: 'Популярный сервис в вашем районе',
    uz: 'Mahalliy ommabop xizmat',
};

export const Recommended_Services = {
    eng: 'Recommended Services',
    ru: 'Рекомендуемые услуги',
    uz: 'Tavsiya etilgan xizmatlar',
};

export const Popular = {
    eng: 'Popular',
    ru: 'Популярный',
    uz: 'Ommabop',
};

export const Pros_near_you = {
    eng: 'Pros near you',
    ru: 'Специалисты близко к вам',
    uz: 'Sizga yaqin mutaxassis',
};

export const More = {
    eng: 'More',
    ru: 'Больше',
    uz: `Ko'proq`,
};

export const More_details = {
    eng: 'More details',
    ru: 'Подробнее',
    uz: 'Batafsil',
};

export const Cancel_request = {
    eng: 'Cancel request',
    ru: 'Отменить запрос',
    uz: 'So‘rovni bekor qilish',
};

export const Open_for_business = {
    eng: 'Open for business',
    ru: 'Открыт для бизнеса',
    uz: 'Xizmatlar doim bor!',
};

export const Whatever_work_you_do = {
    eng: 'Whatever work you do, ',
    ru: 'Какую бы работу вы ни делали,',
    uz: 'Qanday xizmat taklif qilsangiz ham,',
};

export const Whatever_work_you_do2 = {
    eng: 'we\'ll find you the jobs you want',
    ru: 'мы найдем вам работу, которую вы хотите',
    uz: 'sizga kerakli mijozlarni topamiz',
};
export const What_other_info_should_we_pass_along = {
    eng: 'What other info should we pass along',
    ru: 'Какую другую информацию мы должны передать',
    uz: 'Qo\'shimcha qanday ma\'lumotlarni yuboraylik',
};

export const What_service_do_you_offer = {
    eng: 'What service do you offer?',
    ru: 'Какой сервис вы предлагаете?',
    uz: 'Qanday xizmatni taklif qilasiz?',
};

export const Become_a_Top_service_pro = {
    eng: 'Become a TOPX pro',
    ru: 'Станьте специалистом TOPX',
    uz: 'Eng yaxshi TOPX mutaxassisi bo\'ling',
};

export const Consider_it_done = {
    eng: 'Consider it done',
    ru: 'Считайте, что это сделано',
    uz: 'Bajarilgan deb hisoblang',
};

export const About = {
    eng: 'About',
    ru: 'О нас',
    uz: 'Jamoa',
};

export const Careers = {
    eng: 'Careers',
    ru: 'Вакансии',
    uz: 'Vakansiyalar',
};

export const Blog = {
    eng: 'Blog',
    ru: 'Блог',
    uz: 'Blog',
};

export const Customers = {
    eng: 'Customers',
    ru: 'Клиенты',
    uz: 'Mijozlar',
};

export const How_to_use_TopX = {
    eng: 'How to use TOPX',
    ru: 'Как использовать TOPX',
    uz: 'TOPX dan foydalanish',
};

export const Sign_up = {
    eng: 'Sign up',
    ru: 'Регистрация',
    uz: 'Ro\'yhatdan o\'tish',
};

export const Get_the_app = {
    eng: 'Get the app',
    ru: 'Получить приложение',
    uz: 'Ilovani yuklab oling',
};

export const Sent_quotes = {
    eng: 'Sent quotes',
    ru: 'Отправленные заявки',
    uz: 'Yuborilgan takliflar',
};

export const Questions = {
    eng: 'Questions',
    ru: 'Вопросы',
    uz: 'Savol',
};

export const Questions_and_answers = {
    eng: 'Questions and answers',
    ru: 'Вопросы и ответы',
    uz: 'Savol va javoblar',
};

export const Pros = {
    eng: 'Pros',
    ru: 'Специалисты',
    uz: 'Mutaxassislar',
};

export const Pros_who_connected_with_you = {
    eng: 'The pros who connect with you',
    ru: 'Специалисты, которые с вами связались',
    uz: 'Siz bilan bog\'langan mutaxasislar',
};

export const Pros_we_recommend = {
    eng: 'The pro\'s who we recommend',
    ru: 'Специалисты, которых мы рекомендуем',
    uz: 'Tavsiya etadigan mutaxassislar',
};

export const Wait_for_the_pros_to_contact_you = {
    eng: 'Wait for the pros to contact you',
    ru: 'Подождите, пока с вами свяжутся специалисты',
    uz: 'Mutaxasislar siz bilan bog\'lanishini kuting',
};

export const Topx_for_pros = {
    eng: 'TOPX for pros',
    ru: 'TOPX для специалистов',
    uz: 'TOPX usta va mutahassislarga',
};

export const Sign_up_as_a_pro = {
    eng: 'Sign up as a pro',
    ru: 'Присоединяйся как специалист',
    uz: 'Mutaxassis ro\'yhatidan o\'tish',
};
export const WhatAre={
    eng: 'What are these?',
    ru: 'Что это значит?',
    uz: 'Bular nimalarni bildiradi?'
};
export const ToolT1={
    eng: 'First column shows how many specialists have contacted this client. Maximum of 3 specialists may contact 1 customer. \
         <br>Second column indicates the cost per client. 1 Coin = 2500 sum.\n \
         <br>Third column shows task expiration date. The job expires after 72 hours.',
    ru: 'Первая колонка показывает, сколько специалистов связались с этим клиентом. Максимум 3 специалиста могут обратиться к одному клиенту.\n \
    <br>Во втором столбце указана стоимость на одного клиента. 1 Coin = 2500 сум.\n \
    <br> В третьем столбце указана дата окончания срока действия задачи. Срок действия заказа истекает через 72 часа.',
    uz: 'Birinchi ustunda mijoz bilan qancha mutaxassis bog\'langanligi ko\'rsatilgan. Bitta mijoz bilan 3 tagacha mutaxassis bog\'lanishi mumkin.\n \
<br>Ikkinchi ustun har bir mijoz uchun tólovni kórsatadi. 1 Coin = 2500 so\'m.\n \
<br>Uchinchi ustunda buyurtmaning amal qilish muddati kórsatilgan. Har bir buyurtma 72 soatda muddati yakunlanadi.'



};

export const ToolT2={
    eng: 'Phone number will be visible after you contact client.<br> You may buy the client by paying required COINS. <br>Then, you can message the customer.',
    ru: 'Номер телефона будет доступен после отправки сообщения клиенту.<br> Чтобы связаться с клиентом, вам нужно купить COINS.',
    uz: 'Telefon raqami mijozga xabar yuborilgandan sóng kórinadi. <br>Mijoz bilan bog\'lanish uchun COINS sotib olishingiz kerak.'
};
export const TT3={
    eng: 'Templates help you to save time by saving pre written messages.<br> You may use the same message or offers for all customers. <br>Create several templates and rename them if you wish.',
    ru:  'Сохраните сообщение как шаблон и сэкономьте время при обращении к клиентам.<br>  Вы можете создать несколько шаблонов  для разных продуктов и услуг.',
    uz: 'Xabaringizni shablon sifatida saqlang va mijozlarga murojaat yuborganda vaqtni tejang. <br>Turli xil mahsulotlar va xizmatlar uchun bir nechta shablonlarni yaratishingiz mumkin.'

};
export const Tashkent = {
    eng: 'Tashkent',
    ru: 'Ташкент',
    uz: 'Toshkent',
};

export const New_opportunities = {
    eng: 'New opportunities in your business',
    ru: 'Новые возможности для бизнеса',
    uz: 'Biznesingiz uchun yangi imkoniyatlar',
};

export const Community = {
    eng: 'Community',
    ru: 'Сообщество',
    uz: 'Jamoa',
};

export const Success_stories = {
    eng: 'Success stories',
    ru: 'Истории успеха',
    uz: 'Muvaffaqiyat tarixi',
};

export const Pro_reviews = {
    eng: 'Pro reviews',
    ru: 'Отзывы специалиста',
    uz: 'Mutaxassis sharhlari',
};

export const Daily = {
    eng: 'Daily',
    ru: 'Ежедневно',
    uz: 'Har kuni',
};

export const Are_you_happy_with_the_result = {
    eng: 'Are you satisfied with the result?',
    ru: 'Вы довольны результатом?',
    uz: 'Natijadan mamnunmisiz?',
};

export const iPhone_app_for_pros = {
    eng: 'iPhone app for pros',
    ru: 'iPhone приложение для профессионалов',
    uz: 'Mutaxassis uchun iPhone ilova',
};

export const Android_app_for_pros = {
    eng: 'Android app for pros',
    ru: 'Android приложение для профессионалов',
    uz: 'Mutaxassis uchun Android ilova',
};

export const Help = {
    eng: 'Help',
    ru: 'Помощь',
    uz: 'Yordam',
};

export const Safety = {
    eng: 'Safety',
    ru: 'Безопасность',
    uz: 'Xavfsizlik',
};

export const Terms_of_Use = {
    eng: 'Terms of Use',
    ru: 'Условия пользования',
    uz: 'Foydalanish shartlari',
};

export const Archived = {
    eng: 'Archived',
    ru: 'Архив',
    uz: 'Arxiv',
};

export const Privacy_Policy = {
    eng: 'Privacy Policy',
    ru: 'Конфиденциальность',
    uz: 'Shaxsiy ma\'lumotlar tizimi',
};

export const and = {
    eng: 'and',
    ru: 'и',
    uz: 'va',
};

export const Support = {
    eng: 'Support',
    ru: 'Поддержка',
    uz: 'Ko\'mak',
};

export const Opportunities = {
    eng: 'Opportunities',
    ru: 'Перспективы',
    uz: 'Imkoniy mijozlar',
};

export const comments = {
    eng: 'comments',
    ru: 'Комментарии',
    uz: 'Izohlar',
};

export const date = {
    eng: 'date',
    ru: 'дата',
    uz: 'sana',
};

export const Time_of_day = {
    eng: 'Time of day',
    ru: 'Время суток',
    uz: 'Kun vaqti',
};

export const Early_Morning = {
    eng: 'Early Morning',
    ru: 'Раннее утро',
    uz: 'Erta tong',
};

export const before = {
    eng: 'before',
    ru: 'до',
    uz: 'avval',
};

export const Evening = {
    eng: 'Evening',
    ru: 'Вечер',
    uz: 'Kechki payt',
};

export const After_customers_pick_you_those_direct_leads_show_up_here = {
    eng: 'After customers pick you, those direct leads show up here.',
    ru: 'После того, как клиенты выбирают вас, эти прямые лиды появляются здесь.',
    uz:
    'Mijozlar sizni tanlagandan so\'ng, to\'g\'ridan-to\'g\'ri ko\'rsatmalar bu erda paydo bo\'ladi.',
};

export const Paid = {
    eng: 'Paid',
    ru: 'Оплаченный',
    uz: 'To\'langan',
};

export const Sorry_this_order_is_closed = {
    eng: 'Sorry, this order is closed',
    ru: 'Извините, этот заказ закрыт',
    uz: 'Kechirasiz, bu buyurtma yopiq',
};

export const Other = {
    eng: 'Other',
    ru: 'Другое',
    uz: 'Boshqa',
};

export const Other_describe = {
    eng: 'Other (please describe)',
    ru: 'Другое (пожалуйста, опишите)',
    uz: 'Boshqa (iltimos, ta\'riflab bering)',
};

export const You_can_see_other_open_orders = {
    eng: 'You can see other open orders',
    ru: 'Вы можете увидеть другие открытые ордера',
    uz: 'Boshqa ochiq buyurtmalarni ko\'rishingiz mumkin',
};

export const Only_picked_you = {
    eng: 'Only picked you',
    ru: 'Только выбрал вас',
    uz: 'Faqat sizni tanlagan',
};

export const Accepted = {
    eng: 'Accepted',
    ru: 'Утвержденный,',
    uz: 'Qabul qilingan',
};

export const View_details = {
    eng: 'View details',
    ru: 'Посмотреть детали',
    uz: 'Tafsilotlarni ko\'rish',
};

export const Pass = {
    eng: 'Pass',
    ru: 'Пас',
    uz: 'Pass',
};

export const pros_contacted = {
    eng: 'pros contacted',
    ru: 'специалисты связались',
    uz: 'mutaxassis bog\'landi',
};

export const Credits = {
    eng: 'Credits',
    ru: 'Кредиты',
    uz: 'Kreditlar',
};

export const Coins = {
    eng: 'Coins',
    ru: 'Coins',
    uz: 'Coins',
};

export const until_request_expires = {
    eng: 'until request expires',
    ru: 'до истечения срока',
    uz: 'buyurtma muddati tugaydi',
};

export const Choose_template = {
    eng: 'Choose template',
    ru: 'Ответить клиенту',
    uz: 'Mijozga javob berish',
};

export const Reply_to_client = {
    eng: 'Reply to client',
    ru: 'Ответить клиенту',
    uz: 'Mijoz bilan bog’lanish',
};

export const What_service = {
    eng: 'What service are you looking for?',
    ru: 'Какую услугу вы ищите?',
    uz: 'Sizga qanday xizmat kerak?',
};

export const Clear_template = {
    eng: 'Clear template',
    ru: 'Очистить шаблон',
    uz: 'Shablonni tozalash',
};

export const Templates = {
    eng: 'Templates',
    ru: 'Шаблон',
    uz: 'Shablon',
};

export const Client = {
    eng: 'Client',
    ru: 'Клиент',
    uz: 'Mijoz',
};

export const viewed_your_quote = {
    eng: 'viewed your quote',
    ru: 'просмотрел ваше предложение',
    uz: 'sizning taklifingizni kōrib chiqdi.',
};

export const sum = {
    eng: 'sum',
    ru: 'сум',
    uz: 'sōm',
};

export const Sum = {
    eng: 'Sum',
    ru: 'Сумма',
    uz: 'So\'m',
};

export const Amount = {
    eng: 'Amount',
    ru: 'Сумма',
    uz: 'Miqdor',
};



export const Pay = {
    eng: 'Pay',
    ru: 'Оплатить',
    uz: 'To\'lash',
};

export const What_time_you_prefer = {
    eng: 'What time of day do you prefer?',
    ru: 'Какое время дня вы предпочитаете?',
    uz: 'Qaysi vaqt sizga tōg\'ri keladi?',
};

export const What_is_your_budget = {
    eng: 'What is your budget?',
    ru: 'Сколько готовы заплатить за работу?',
    uz: 'Ish uchun qancha tōlashga tayyorsiz?',
};

export const What_is_your_price = {
    eng: 'What is your price?',
    ru: 'стоимость вашей услуги (не обязательно)',
    uz: 'xizmat narxi (ixtiyoriy)',
};

export const Write_a_message = {
    eng: 'Write a message',
    ru: 'Написать сообщение',
    uz: 'Xabar yozing',
};

export const Your_message = {
    eng: 'Your Message ...',
    ru: 'Твое сообщение ...',
    uz: 'Sizning Xabaringiz ...',
};

export const Let = {
    eng: 'Let',
    ru: 'Дайте',
    uz: '',
};

export const know_you_interested = {
    eng: ' know you\'re interested.',
    ru: ' знать, что вы заинтересованы.',
    uz: 'ga qiziqishingizni bildiring.',
};

export const No_pros_with_your_choice_yet = {
    eng: 'No pros with your choice yet.',
    ru: 'Специалистов по вашему выбору пока нет.',
    uz: 'Siz istagan mutaxassislar topilmadi.',
};

export const wants_your_availability = {
    eng: 'wants to know your availability',
    ru: 'хочет стать вашим клиентом',
    uz: 'mijozingiz bo\'lishni istaydi',
};
export const Text01={
    eng: 'Here you can see the list of clients who are suitable for the service you offer. <br>The order will be available for 72 hours.',

    ru: 'Здесь вы можете ознакомиться со списком клиентов, которые подходят на предлагаемую вами услугу.  <br>Заказ будет доступен 72 часа.',

    uz: 'Bu yerda siz taklif etgan xizmatga mos keladigan mijozlar ro’yhati bilan tanishishingiz mumkin.  <br>Buyurtmaning amal qilish muddati 72 soat.'
};

export const Text02={
    eng: 'A potential client may be someone who is still waiting for a response from a professional with a real order.<br> The order will be available for another 48 hours.<br> After that, the order will be deleted.',

    ru: 'Потенциальный клиент — это может быть тот, кто все еще ждет ответа от профессионала с реальным заказом.<br> Заказ будет доступен еще 48 часов. <br>После этого заказ будет удален.',

    uz: 'Potentsial mijoz - bu mutaxassisdan hali ham javob kutayotgan buyurtma egasi. <br>Ushbu buyurtma yana 48 soat mavjud bo\'ladi. <br>So’ng, butunlay bekor qilinadi.'

};
export const Text03={
    eng: 'Here will be a list of new customers',
    ru: 'Здесь будет список новых клиентов',
    uz: 'Bu yerda yangi mijozlar ro’yhati ko’rsatiladi'
};
export const Fixed_price = {
    eng: 'Fixed price',
    ru: 'Стоимость услуги',
    uz: 'Xizmat narxi',
};

export const Need_more_info = {
    eng: 'Need more info',
    ru: 'Нужно больше информации',
    uz: 'Qo\'shimcha ma\'lumot kerak',
};

export const NA = {
    eng: 'N/A',
    ru: 'Нужна/Инф.',
    uz: 'Aniqlik kiriting',
};

export const Additional_details = {
    eng: 'Additional details',
    ru: 'Дополнительные детали',
    uz: 'Qo\'shimcha ma\'lumotlar',
};

export const Unread = {
    eng: 'Unread',
    ru: 'Не прочитано',
    uz: 'O\'qilmagan',
};

export const Starred = {
    eng: 'Starred',
    ru: 'Помеченные',
    uz: 'Asosiy',
};

export const Hired = {
    eng: 'Hired',
    ru: 'Принят на работу',
    uz: 'Yollangan',
};

export const hired = {
    eng: 'hired',
    ru: 'принят на работу',
    uz: 'yollangan',
};

export const Change_name = {
    eng: 'Change name',
    ru: 'Переименовать',
    uz: 'Nomini ōzgartir',
};

export const months_ago = {
    eng: 'months ago',
    ru: 'месяцев назад',
    uz: 'oy avval',
};

export const days_ago = {
    eng: 'days ago',
    ru: 'дней назад',
    uz: 'kun avval',
};

export const hours_ago = {
    eng: 'hours ago',
    ru: 'часов назад',
    uz: 'soat oldin',
};

export const minutes_ago = {
    eng: 'minutes ago',
    ru: 'минут назад',
    uz: 'daqiqa oldin',
};

export const seconds_ago = {
    eng: 'seconds ago',
    ru: 'секунд назад',
    uz: 'soniya oldin',
};

export const Type_here = {
    eng: 'Type here',
    ru: 'Введите здесь',
    uz: 'Shu yerga yozing',
};

export const Type_message = {
    eng: 'Type message',
    ru: 'Введите сообщение',
    uz: 'Xabarni kiriting',
};

export const All_services = {
    eng: 'All services',
    ru: 'Все услуги',
    uz: 'Barcha xizmatlar',
};

export const All_services2 = {
    eng: 'All Categories',
    ru: 'Все Toifalar',
    uz: 'Barcha Kатегории',
};
export const Targeting_on = {
    eng: 'Targeting on',
    ru: 'Активированные услуги',
    uz: 'Amaldagi xizmatlar',
};

export const Targeting_off = {
    eng: 'Targeting off',
    ru: 'Отключенные услуги',
    uz: 'Nofaol xizmatlar',
};

export const Targeted = {
    eng: 'Targeted',
    uz: 'Targeted',
    ru: 'Целевое',
};

export const Standart = {
    eng: 'Standart',
    uz: 'Standart',
    ru: 'Стандарт',
};

export const Add_Service = {
    eng: 'Add Service',
    ru: 'Добавить услугу',
    uz: 'Xizmatni qo\'shish',
};

export const Service_added = {
    eng: 'Service added',
    ru: 'Сервис добавлен',
    uz: 'Xizmat qo\'shildi',
};

export const Select_Service = {
    eng: 'Select Service',
    ru: 'Выберите Сервис',
    uz: 'Xizmatni tanlang',
};

export const Close_Service = {
    eng: 'Close Service',
    ru: 'Закрыть Сервис',
    uz: 'Xizmatni yopish',
};

export const Please_try_again = {
    eng: 'Please try again',
    ru: 'Пожалуйста, попробуйте еще раз',
    uz: 'Iltimos, yana bir bor urinib ko\'ring',
};

export const All_set_successfully = {
    eng: 'All set successfully',
    ru: 'Все успешно установлено',
    uz: 'Hammasi muvaffaqiyatli amalga oshirildi',
};

export const Please_wait_the_answer_of_pro = {
    eng: 'Please wait the answer of pro',
    ru: 'Пожалуйста, подождите ответ про',
    uz: 'Iltimos, mutaxassis javobini kuting',
};

export const Hide_my_business_temporarily = {
    eng: 'Hide my business temporarily',
    ru: 'Скрыть мой бизнес временно',
    uz: 'Xizmatni vaqtincha to\'xtatish',
};

export const times = {
    eng: 'times',
    ru: 'раз',
    uz: 'marta',
};

export const hour = {
    eng: 'hour',
    ru: 'час',
    uz: 'soat',
};

export const Phone = {
    eng: 'Phone',
    ru: 'Телефон',
    uz: 'Telefon',
};

export const Phone_sidebar = {
    eng: 'Phone',
    ru: 'Мобильный телефон',
    uz: 'Mobil raqam',
};

export const Website = {
    eng: 'Website',
    ru: 'Веб-сайт',
    uz: 'Veb-sayt',
};

export const Address = {
    eng: 'Address',
    ru: 'Адрес',
    uz: 'Manzil',
};

export const Year_founded = {
    eng: 'Year founded',
    ru: 'Год основания',
    uz: 'Tashkil topgan yil',
};

export const Number_of_employees = {
    eng: 'Number of employees',
    ru: 'Количество сотрудников',
    uz: 'Xodimlar soni',
};

export const Please_answer_the_question = {
    eng: 'Please answer the question',
    ru: 'Пожалуйста, ответьте на вопрос',
    uz: 'Iltimos savolga javob bering',
};

export const Next_step = {
    eng: 'Next Step',
    ru: 'Продолжить',
    uz: 'Keyingisi',
};

export const Prev_step = {
    eng: 'Previous Step',
    ru: 'Предыдущий шаг',
    uz: 'Oldingisi',
};

export const Back = {
    eng: 'Back',
    ru: 'Назад',
    uz: 'Ortga',
};
export const Next = {
    eng: 'Next',
    ru: 'Следующий',
    uz: 'Keyingi',
};
export const Submit = {
    eng: 'Submit',
    ru: 'Разместить',
    uz: 'Yuborish',
};

export const viewpro_details={
    eng: 'View Pro Details',
    ru: 'подробно о специалисте ',
    uz: 'mutaxassis ma\'lumotlari '
};

export const View_project = {
    eng: 'View project',
    ru: 'Посмотреть проект',
    uz: 'Ko\'rish',
};

export const Service_details = {
    eng: 'Service details',
    ru: 'Подробности об услуге',
    uz: 'Xizmat haqida ma\'lumot',
};
export const Photos_videos = {
    eng: 'Project gallery',
    ru: 'Галерея ваших работ',
    uz: 'Qilgan ishlaringiz galereyasi',
};

export const BuisnesName ={
    eng: 'Business Name (if any)',
    ru: 'Название фирмы (если есть)',
    uz: 'Firma nomi (agar mavjud bólsa)',
};
export const AddressO={
    eng: 'Address (optional)',
    ru: 'Адрес (необязательно)',
    uz: 'Manzil (majburiy emas)',
};
export const PhonDE2={
    eng:'To change your main mobile number visit',
    ru:'Вы можете изменить основной мобильный номер в',
    uz:'\nAsosiy mobil raqamingizni ózgartirish uchun'
};

export const PhoneDE={
    eng:`Hidden until customer contacts you.`,
    ru:'Отображается только для клиентов, с которыми вы связались. ',
    uz:'Faqat siz murojaat qilgan mijozlar kóra oladi.'
};

export const BuisnessP={
    eng:' Business phone if any (office number etc. Optional)',
    ru: 'Отдельный рабочий телефон, если имеется (Необязательно)',
    uz: 'Alohida Ish telefoni, agar bor bo\'lsa (ofis raqami va hk... Majburiy emas)',
};
export const WebsiteO={
    eng: 'Website (optional)',
    ru: 'Веб-сайт (необязательно)',
    uz: 'Websayt (majburiy emas)'
};

export const Photos_and_videos = {
    // eng: 'Photos and videos',
    // ru: 'Фото и видео',
    // uz: 'Rasmlar va videolar',
    eng: 'Photos',
    ru: 'Фотографии',
    uz: 'Rasmlar',
};

export const Upload = {
    eng: 'Upload',
    ru: 'Загрузить',
    uz: 'Yuklash',
};

export const Show_off_your_business = {
    eng: 'Show off your business',
    ru: 'Покажите свое портфолио',
    uz: 'Bajargan ishlaringizni ko\'rsating',
};

//  export Iconst nclude_photos_of_your_work_(before_and_afte =),_team,_workspace_or_equipment.: {
//     eng: "Include photos of your work (before and after), team, workspace or equipment.",
//     ru: "Включите фотографии вашей работы (до и после), команды, рабочего пространства или оборудования",
//     uz: "Bajargan ishingiz (oldin va keyin), guruh, ish maydoni yoki jihozlarning fotosuratlarini qo'shing."
//   }

export const Add_photos = {
    eng: 'Add photos',
    ru: 'Добавить фотографии',
    uz: 'Rasm qo\'shish',
};

export const Social_media = {
    eng: 'Social media',
    ru: 'Социальные сети',
    uz: 'Ijtimoiy tarmoq',
};

export const Add = {
    eng: 'Add',
    ru: 'Добавить',
    uz: 'Qo\'shish',
};

export const Payment_methods_accepted = {
    eng: 'Payment methods accepted',
    ru: 'Способы оплаты',
    uz: 'To\'lov usullari',
};

export const Your_introduction = {
    eng: 'Your introduction',
    ru: 'О вашем сервисе',
    uz: 'Xizmatingiz haqida',
};

export const Edit_Business_Details = {
    eng: 'Edit Business Details',
    ru: 'Изменить информацию о бизнесе',
    uz: 'Biznes tafsilotlarini tahrirlash',
};

export const Business_Profile_Image = {
    eng: 'Business Profile Image',
    ru: 'Бизнес профиль изображения',
    uz: 'Biznes Profil rasmi',
};

export const Business_Name = {
    eng: 'Business Name',
    ru: 'Наименование фирмы',
    uz: 'Korxona nomi',
};

export const Introduction = {
    eng: 'Introduction',
    ru: 'Описание',
    uz: 'Ta\'rif',
};

//  export Cconst ustomers_rated_this_pro_highly_for_punctuality,_valu =,_and_responsiveness.: {
//     eng: "Customers rated this pro highly for punctuality and responsiveness.",
//     ru: "Клиенты высоко оценили этого профессионала за пунктуальность и отзывчивость.",
//     uz: "Mijozlar ushbu mutaxassisni o'z vaqtida va e'tiborli bo'lganiga yuqori baholadilar."
//   }

export const No_Comment = {
    eng: 'No Comment',
    ru: 'Нет комментариев',
    uz: 'Izoh yo\'q',
};

export const Business_hours = {
    eng: 'Business hours',
    ru: 'Часы работы',
    uz: 'Ish soatlari',
};

export const Days_remaining = {
    eng: 'days',
    ru: 'дня',
    uz: 'kunda',
};

export const hours_remaining = {
    eng: 'in hours',
    ru: 'часа',
    uz: 'soatda',
};

export const minutes_remaining = {
    eng: 'in minutes',
    ru: 'минут',
    uz: 'daqiqada',
};

export const Monday = {
    eng: 'Monday',
    ru: 'Понедельник',
    uz: 'Dushanba',
};

export const Tuesday = {
    eng: 'Tuesday',
    ru: 'Вторник',
    uz: 'Seshanba',
};

export const Wednesday = {
    eng: 'Wednesday',
    ru: 'Среда',
    uz: 'Chorshanba',
};

export const Thursday = {
    eng: 'Thursday',
    ru: 'Четверг',
    uz: 'Payshanba',
};

export const Friday = {
    eng: 'Friday',
    ru: 'Пятница',
    uz: 'Juma',
};

export const Saturday = {
    eng: 'Saturday',
    ru: 'Суббота',
    uz: 'Shanba',
};

export const Sunday = {
    eng: 'Sunday',
    ru: 'Воскресенье',
    uz: 'Yakshanba',
};

export const Closed = {
    eng: 'Closed',
    ru: 'Закрыто',
    uz: 'Yopiq',
};

export const Frequently_asked_questions = {
    eng: 'Frequently asked questions',
    ru: 'Часто задаваемые вопросы',
    uz: 'Tez-tez so\'raladigan savollar',
};

export const No_Data = {
    eng: 'No Data',
    ru: 'Нет данных',
    uz: 'Ma\'lumot yo\'q',
};

export const Projects = {
    eng: 'Projects',
    ru: 'Проекты',
    uz: 'Loyihalar',
};

export const You_have_sent_a_request_to = {
    eng: 'You have sent a request to',
    ru: 'Вы отправили запрос в',
    uz: 'Siz so\'rov yuborgan vaqt',
};

export const Review = {
    eng: 'Review',
    ru: 'Оценить',
    uz: 'Baholash',
};

export const Rate_a_pro = {
    eng: 'Rate a pro',
    ru: 'Оцените специалиста',
    uz: 'Mutaxassisga baho bering',
};

export const Leave_a_review = {
    eng: 'Leave a review',
    ru: 'Оставить отзыв',
    uz: 'Fikr-mulohaza bildiring',
};

export const Message_details_button = {
    eng: 'Message',
    ru: 'Написать сообщение',
    uz: 'Xabar yo\'llash',
};

export const Provide_details_so_that_specialists = {
    eng:
    'Provide details so that specialists can accurately estimate the volume of work.',
    ru:
    'Укажите подробности, чтобы специалисты могли точно оценить объем работы.',
    uz: 'Mutaxassis ish hajmini aniq baholashi uchun ma\'lumotlarni yozing.',
};



export const Message = {
    eng: 'Message',
    ru: 'Cообщение',
    uz: 'Xabar',

};
export const Reviewss = {
    eng: 'Reviews',
    ru: 'Отзывы',
    uz: 'Sharhlar',
};
export const Reviews = {
    eng: 'Reviews',
    ru: 'Отзывы',
    uz: 'Sharhlar',
};

export const reviews = {
    eng: 'reviews',
    ru: 'отзывы',
    uz: 'sharhlar',
};

export const MoreReview={
    eng: 'Get more reviews and attention. \
<br>\
Ask your customers to leave a review for you on TOPX.',

    ru:'Получайте больше отзывов и привлекайте больше внимания.\
<br>\
Попросите своих клиентов оставить для вас отзыв на TOPX.',

    uz: 'Kóproq sharhlar oling va kóproq e\'tibor oling.\
<br>\
Mijozlaringizdan TOPX -da siz uchun sharh qoldirishni sórang.'

};

export const GReview={
    eng: 'Get reviews from your previous customers.',
    ru: 'Получите отзывы предыдущих клиентов.',
    uz: 'Oldingi mijozlardan ijobiy sharhlar oling.'
};
export const Feedback={
    eng: 'Your feedback has been successfully submitted!',
    ru: 'Спасибо! Ваша отзыв успешно отправлен!',
    uz: 'Tashakkur! Sizning fikringiz qabul qilindi!'
};


export const AskPr={
    eng: 'Ask your previous customers for reviews. Enter their number below and the link will be sent as SMS. You can show up to 10 reviews from previous customers.',
    ru: 'Вежливо спрашивайте отзывы у ваших предыдущих клиентов. Введите их номер ниже, и ссылка будет отправлена как SMS. Вы можете показать до 10 отзывов от предыдущих клиентов.',
    uz: 'Oldingi mijozlardan sharh qoldirishni sórang. Mobil raqamlarni kiriting va mijozlarga SMS link boradi. Mijoz shu link orqali sizga sharh qoldiradi. Profilingizda avvalgi mijozlarning faqatgina 10 ta sharhi ko’rinadi'
};

export const sendSms={

    eng: 'Send automated SMS',
    ru: 'Отправить SMS-сообщение',
    uz: 'SMS shaklida yuborish.'
};
export const Copytext={
    eng:' You may also copy the link below and send it through Telegram.',
    ru:' Вы также можете скопировать ссылку ниже и отправить ее через Telegram.',
    uz: 'Quyidagi linkni nusxalab Telegram orqali mijozlarga yuborishingiz mumkin.'
};
export const sendText={
    eng: 'Message has been sent to your customers. Please don’t forget to remind them about your request.',
    ru: 'Сообщение было отправлено вашим клиентам. Пожалуйста, не забудьте напомнить им о вашей просьбе.',
    uz:' Xabar sizning mijozlarga yuborildi. So’rovingiz haqida ularga og’zaki eslatishni unutmang.'
};
export const sms={


    eng: 'SMS message',
    ru: 'SMS-сообщение',
    uz: 'SMS xabar'
};
export const offerTolltip={
    ru: 'Будьте первым, позвонив клиенту по указанному номеру и поделитесь подробностями.',
    uz: 'Ko\'rsatilgan raqam bo\'yicha mijozga birinchi bo\'lib qo\'ng\'iroq qiling va batafsil ma\'lumot bering.',
    eng: 'Be the first to call the customer at the specified number and share the work details.'
};
export const home={
    eng: 'Home',
    ru: 'Главная',
    uz: 'Bosh sahifa'
};
export const allow10={
    eng: 'You have collected maximum number of reviews allowed from your previous customers.',
    ru: 'Вы собрали максимальное количество отзывов от ваших предыдущих клиентов.',
    uz: 'Siz avvalgi mijozlaringizdan ruxsat etilgan maksimal miqdordagi sharhlarni tópladingiz.'
};
export const submitR={
    eng: 'Submit the review',
    ru: 'Отправить отзыв',
    uz: 'Izohni yubor'
};
export const linktext={
    eng: 'Copy shareable link',
    ru:' Копировать ссылку',
    uz: 'Havolani nusxalash'
};

export const addNu={
    eng: 'Add another number',
    ru:' Добавить другой номер',
    uz:' Yana raqam qóshish'
};
export const AsKR={

    eng: 'Ask for reviews',
    ru: 'Попросить оставить отзывы',
    uz: 'Sharhlar qoldirishni so‘rash'
};
export const NotAv={

    eng: 'This Pro is no longer available',
    ru: 'Этот специалист не доступен в данный момент.',
    uz: 'Ushbu mutaxassis hozir mavjud emas.'
};

export const  Coming_Soon ={
    eng: 'Coming soon...',
    ru: 'Скоро...',
    uz: 'Yaqin orada..'
};

export const FitProjet={
    eng: 'Pro\'s who fit your project',
    ru: 'Специалисты которые подходят вашему проекту',
    uz: 'Loyihangizga mos keladigan mutaxassislar',
};

export const Account_settings = {
    eng: 'Account settings',
    ru: 'Настройки аккаунта',
    uz: 'Profil sozlamalari',
};
export const Settings={
    eng: 'Settings',
    ru: 'Настройки',
    uz: 'Sozlamalar'
};
export const Text05={
    eng: 'Here you can see the client\'s needs and what kind of service the client wants. <br>Read carefully and decide if the job suits your ability.',

    ru: 'Здесь вы можете увидеть потребности клиента и какой сервис клиент желает.<br> Внимательно прочтите и решите, соответствует ли работа вашим способностям.',

    uz: 'Bu yerda mijozning loyiha bo’yicha asosiy talablari ko’rsatilgan. <br>Yaxshilab tanishib chiqib, ish sizning qobiliyatingizga mos keladimi yo\'qligini aniqlang.'

};
export const Text06={
    eng: 'The phone number will be available after sending a message to the client. <br>To contact a customer, you need to buy COINS.',

    ru: 'Номер телефона будет доступен после отправки сообщения клиенту. <br>Чтобы связаться с клиентом, вам нужно купить COINS.',

    uz: 'Telefon raqami mijozga xabar yuborilgandan sóng kórinadi. <br> Mijoz bilan bog\'lanish uchun COINS sotib olishingiz kerak.'
};

export const Text07={
    eng: 'Save the message as a template and save time when contacting customers later. <br>You can create multiple templates for different services.',

    ru: 'Сохраните сообщение как шаблон и сэкономьте время при обращении к клиентам в дальнейшем. <br>Вы можете создать несколько шаблонов для разных услуг.',

    uz: 'Xabaringizni shablon sifatida saqlang va mijozlarga keyingi murojaatlaringizda vaqtni tejang. <br>Turli xil xizmatlar uchun bir nechta shablonlarni yaratish mumkin.'
};
export const Text08={
    eng: 'For the convenience of customers, <br>you can indicate the cost of your service (you can choose the type of calculation from several options specified in the template)',

    ru: 'Для удобства клиентов можете указать стоимость вашей услуги (можете выбрать тип расчета из нескольких вариантов, указанных в шаблоне)' ,

    uz: 'Mijozlarning qulayligi uchun xizmat narxini kórsatishingiz mumkin. <br>(shablonda ko\'rsatilgan bir nechta variantlardan hisoblash turini tanlashingiz mumkin)'
};
export const Text10={
    eng: `You bought an order! You can chat with the client here or call directly. <br>When the client sees your message, <br>you will receive a notification in the form of text: "The client saw your offer." If the client does not see your message within 72 hours,<br> we will refund the paid COINS.`,

    ru: `Вы купили заказ клиента! Вы можете пообщаться с клиентом здесь или позвонить напрямую. <br>Когда клиент увидит ваше сообщение,<br> у вас появится уведомление в виде текста: «Клиент увидел ваше предложение». Если клиент не увидит ваше сообщение в течение 72 часов, <br>мы вернем вам оплаченные COINS.`,

    uz: `Siz mijozning buyurtmasini harid qildingiz! Mijoz bilan suhbat qilishiz yoki to'g'ridan -to'g'ri qóng'iroq qilishingiz mumkin. <br>Mijoz sizning xabaringizni ko'rganda, <br>sizga matnli bildirishnoma keladi: "sizning taklifingizni ko'rildi". Agar mijoz sizning xabaringizni 72 soat ichida kórmasa, <br>to'langan COINS avtomatik qaytarib beriladi.`
};

export const Text12={

    eng: 'The service you provide to your customers.',
    ru: 'Услуга, которую вы оказываете клиентам.',
    uz: 'Siz mijozlarga ko\'rsatadigan xizmatingiz nomi'};

export const Text13={
    eng: 'You can see the details of your services here.',
    ru: 'Вы можете посмотреть детали своих услуг здесь.',
    uz: 'Bu yerda xizmatingiz tafsilotlarini kórishingiz mumkin.'
};
export const Text14={

    eng: 'Here you can add services that you additionally offer. <br>Adding additional services is free!',

    ru: 'Здесь вы можете добавить сервисы которые вы дополнительно предлагаете. <br>Добавление дополнительных услуг БЕСПЛАТНО!',

    uz: 'Bu yerda siz boshqa xizmatlarni qóshishingiz mumkin. <br>Qóshimcha xizmat qo\'shish BEPUL!'};
export const Text09={
    eng: 'Contact the client for more information or suggestions. <br>(You can save the message as a template and save time when contacting customers in the future).',

    ru: 'Свяжитесь с клиентом для получения дополнительной информации или внесения своих предложений.<br> (Можете сохранить сообщение как шаблон и сэкономить время при обращении к клиентам в дальнейшем).',

    uz: 'Mijoz bilan bog’lanib, qo’shimcha ma’lumotlar bering va ajoyib xizmat kórsating. <br> (Bir xil narsani qayta-qayta yozmaslik uchun xabaringizni shablon sifatida saqlab, keyingi murojaatlaringizda ishlating.)'

};
export const Text11={
    eng: `You will have a client's phone number!<br> When you call the customer directly, the transaction will be considered complete,<br> and the COINS cannot be refunded. Remember, <br>if you get a lot of positive reviews, you will be on the TOP for free!`,

    ru: `Вам предоставляется номер клиента! <br>Когда вы звоните клиенту напрямую, транзакция будет считаться завершенной,<br> и COINS не могут быть возмещены. Запомните,<br> если вы получите много положительных отзывов - вы попадете в ТОП бесплатно!`,

    uz: `Sizga mijoz raqami beriladi! <br>Mijozga qo'ng'iroq qilgan vaqt "bitim tugallandi" deb hisoblanadi va COINS qaytarib berilmaydi. <br>Eslatma: agar siz kóplab ijobiy sharhlarga ega bólsangiz – siz TOP'ga bepul chiqasiz!`
};

export const ServicesBe={
    eng: 'Services you might benefit from',
    ru: 'Услуги, которые могут вам пригодиться',
    uz: 'Bu xizmatlar sizga foydali bólishi mumkin',
};
export const ProfF={
    eng: 'Are you a Professional?',
    ru: 'Вы специалист?',
    uz: 'Siz mutaxassismisiz?',
};

export const ParaF={
    eng: 'Join our network',
    ru: 'Станьте специалистом TOPX',
    uz: 'Eng zór TOPX mutaxassisi bóling',
};
export const Para2={
    eng: 'Whatever work you do, we\'ll find you the jobs you want',
    ru: 'Какую бы работу вы ни делали, мы найдем вам работу, которую вы хотите',
    uz: 'Qanday xizmat taklif qilsangiz ham, sizga kerakli mijozlarni topamiz',
};
export const costEst={
    eng: 'Get free cost estimate',
    ru: 'Узнайте о стоимости услуг',
    uz: 'Xizmat narxi haqida ma\'lumot oling'
};

export const YourEmail={

    eng: 'Your email',
    ru: 'Ваш email',
    uz: 'Hozirgi emal'
};
export const NPas={

    eng:' New password',
    ru:' Новый пароль',
    uz:' Yangi parol',
};
export const PSet={
    eng: 'Profile Settings',
    ru: 'Настройки профиля',
    uz:' Profil sozlamalari'
};

export const Nfunds={
    eng: 'Funds:',
    ru:' Ваш счет:',
    uz: 'Hisobingiz:'
};
export const DLMA={

    eng: 'Delete my account',
    ru:' Деактивировать аккаунт',
    uz: 'Akkauntni óchirish',

};
export const CPass={

    eng: 'Your current password',
    ru: 'Текущий пароль',
    uz: 'Joriy parolingiz'

};
export const NewMail={

    eng: 'New Email',
    ru: 'Новый Email-адрес',
    uz:' Yangi Email'
};
export const SaveI={
    eng: 'Save',
    ru:' Сохранить',
    uz: 'Saqlash',
};

export const Cash = {
    eng: 'cash',
    ru: 'наличные',
    uz: 'naqd',
};

export const Notification_settings = {
    eng: 'Notification settings',
    ru: 'Настройки уведомлений',
    uz: 'Xabarnoma sozlamalari',
};

export const Log_out = {
    eng: 'Log out',
    ru: 'Выйти',
    uz: 'Chiqish',
};

export const Change_avatar = {
    eng: 'Change avatar',
    ru: 'Сменить аватар',
    uz: `Avatarni o'zgartiring`,
};

export const Save = {
    eng: 'Save',
    ru: 'Сохранить',
    uz: 'Saqlash',
};

export const Save_Changes = {
    eng: 'Save Changes',
    ru: 'Сохранить изменения',
    uz: `O'zgarishlarni saqlash`,
};

export const Cancel = {
    eng: 'Cancel',
    ru: 'Отмена',
    uz: 'Bekor qilish',
};

export const First_name = {
    eng: 'First name',
    ru: 'Имя',
    uz: 'Ism',
};

export const Last_name = {
    eng: 'Last name',
    ru: 'Фамилия',
    uz: 'Familiya',
};

export const Email = {
    eng: 'Email',
    ru: 'Эл. почта',
    uz: 'Elektron pochta',
};

export const addEmail={
    eng: 'please add your email',
    ru: 'введите ваш email',
    uz: 'email manzilni kiriting'
};
export const Email_or_phone = {
    eng: 'Email or Phone number',
    ru: 'Email или Номер телефона',
    uz: 'Email yoki Mobil raqami’',
};

export const Phone_number = {
    eng: 'Phone number',
    ru: 'Номер телефона',
    uz: 'Telefon raqami',
};

export const Telegram_username = {
    eng: 'Telegram username',
    ru: 'Имя пользователя Telegram',
    uz: 'Telegram foydalanuvchi nomi',
};

export const Morning = {
    eng: 'Morning',
    ru: 'Утро',
    uz: 'Tongda',
};

export const Afternoon = {
    eng: 'Afternoon',
    ru: 'После полудня',
    uz: 'Peshindan keyin',
};

export const Late_Afternoon = {
    eng: 'Late Afternoon',
    ru: 'Ближе к вечеру',
    uz: 'Kun yarmida',
};

export const after = {
    eng: 'after',
    ru: 'после',
    uz: 'keyin',
};

export const Filter = {
    eng: 'Filter',
    ru: 'Фильтр',
    uz: 'Filtr',
};

export const When_is_the_event = {
    eng: 'When is the event',
    ru: 'Когда это событие',
    uz: 'Qachonga',
};

export const Select_date = {
    eng: 'Select date',
    ru: 'Выберите дату',
    uz: 'Sanani tanlang',
};

export const Today = {
    eng: 'Today',
    ru: 'Cегодня',
    uz: 'Bugun',
};

export const starting_cost = {
    eng: 'Starting cost',
    ru: 'Начальная цена',
    uz: 'Boshlang\'ich narxi',
};

export const estimated_cost = {
    eng: 'estimated cost',
    ru: 'ориентировочная стоимость',
    uz: 'taxminiy narx',
};

export const View_profile = {
    eng: 'View profile',
    ru: 'Просмотреть профиль',
    uz: 'Profilni ko\'rish',
};

export const contact_for_price = {
    eng: 'contact for price',
    ru: 'связаться по цене',
    uz: 'narx uchun bog\'laning',
};


export const NoDistrict={
    eng: 'No districts selected',
    ru: 'Регионы не выбраны',
    uz: 'Shahar va yoki tumanlar tanlanmagan'
};
export const NoPrice={
    eng: 'No price yet',
    ru: 'Информация о ценах не указана',
    uz: 'Narxlar haqida ma\'lumot kiritilmagan'
};

export const workTime={
    eng: 'Work time not set yet',
    ru: 'Часы работы не указаны',
    uz: 'Ish vaqtlari belgilanmagan'
};


export const year_in_business = {
    eng: 'year in business',
    ru: 'год(а) в бизнесе',
    uz: 'yil faoliyatda',
};

export const years_of_experience = {
    eng: 'years of experience ',
    ru: 'года опыта',
    uz: 'yillik tajriba',
};

export const employees = {
    eng: 'employees',
    ru: 'сотрудников',
    uz: 'xodimlar',
};

export const please_answer_the_question = {
    eng: 'please answer the question.',
    ru: 'пожалуйста, ответьте на необходимые вопросы.',
    uz: 'iltimos, kerakli savollarga javob bering.',
};

export const Background_checked = {
    eng: 'Background checked',
    ru: 'Бизнес проверен',
    uz: 'Faoliyat tekshirildi',
};

export const Menu = {
    eng: 'Menu',
    ru: 'Меню',
    uz: 'Menyu',
};

export const Payment_methods = {
    eng: 'Payment methods',
    ru: 'Способы оплаты',
    uz: 'To\'lov usullari',
};

export const Overview = {
    eng: 'Overview',
    ru: 'Краткий обзор',
    uz: 'Qisqacha',
};

export const Confirm_password = {
    eng: 'Confirm password',
    ru: 'Повторите пароль',
    uz: 'Parolni qayta kiriting',
};

export const Get_started = {
    eng: 'Get started',
    ru: 'Начать',
    uz: 'Boshlash',
};

export const Add_job_category = {
    eng: 'Add your job category',
    ru: 'Добавить свою специальность',
    uz: 'Ish kategoriyani qo\'shing',
};

export const Interior_designer = {
    eng: 'Interior designer',
    ru: 'Интерьер дизайнер',
    uz: 'Interyer dizayner',
};

export const Job_category_name = {
    eng: 'Job category name',
    ru: 'Вид деятельности',
    uz: 'Ish faoliyat turi',
};

export const Job_area = {
    eng: 'Job area',
    ru: 'Зона деятельности',
    uz: 'Faoliyat joylari',
};

export const workAreas = {
    eng: 'Choose where you work.',
    ru: 'Выберите рабочие зоны.',
    uz: 'Ish hududlarni tanlang.',
};

export const workAreasDisclaimer = {
    eng: 'We will only send clients who request service in your selected areas',
    ru: 'Выберите желаемый регион, и мы будем отправлять клиентов из этой зоны.',
    uz: 'Siz tanlagan hududlarda xizmat kórsatishni sóragan mijozlarni yuboramiz.',
};

export const workAreasEplanation = {
    eng:'Below are the cities you selected. We will only send clients who request service in your selected areas.',
    ru: 'Ниже перечислены выбранные вами города. Выберите желаемый регион, и мы будем отправлять клиентов из этой зоны.',
    uz: 'Quyida siz tanlagan shaharlar kórsatilgan. Eslatma: Faqat siz tanlagan hududlarda xizmat kórsatishni sóragan mijozlarni yuboramiz.',
};

export const selectAll = {
    eng: 'SELECT ALL',
    ru: 'ВЫБРАТЬ ВСЕ',
    uz: 'BARCHASINI TANLA',
};
export const unselectAll = {
    eng: 'UNSELECT ALL',
    ru: 'СНЯТЬ ВСЕ',
    uz: 'BARCHASINI OCH',
};

export const clearAll = {
    eng: 'Clear all',
    ru: 'Убрать все',
    uz: 'Barchasini tozala',
};

export const allDistricts = {
    eng: 'all districts',
    ru: 'все районы',
    uz: 'barcha tumanlar',

};

export const selectedDistricts = {
    eng: 'selected districts...',
    ru: 'отдельные районы...',
    uz: 'alohida tanlangan tumanlar...',

};

export const Potential_Clients = {
    eng: 'Potential Clients',
    ru: 'Потенциальные клиенты',
    uz: 'Potentsial mijozlar',
};

export const Favorites = {
    eng: 'Favorites',
    ru: 'Избранные',
    uz: 'Tanlangan',
};

export const Trash = {
    eng: 'Trash',
    ru: 'Корзина',
    uz: 'Savat',
};

export const To = {
    eng: 'to',
    ru: 'до',
    uz: 'gacha',
};

export const From = {
    eng: 'from',
    ru: 'с',
    uz: 'dan',
};

export const Search_for_message = {
    eng: 'Search from message',
    ru: 'Искать из сообщении',
    uz: 'Xabarlardan qidirish',
};

export const Enter_price_manually = {
    eng: 'Enter the price manually',
    ru: 'Введите цену вручную',
    uz: 'Narxni qo\'lda kiritish',
};

export const Average_cost = {
    eng: 'Average cost',
    ru: 'Рыночная цена',
    uz: 'Bozor narxi',
};

export const Square_meter = {
    eng: 'Square meter cost',
    ru: 'Цена за квадрат метр',
    uz: 'Kvadrat metr narxi',
};

export const Switch_to_customer = {
    eng: 'Switch to Client',
    ru: 'Перейти к профилю клиента',
    uz: 'Mijoz profiliga ótish',
};

export const Service_Pricing = {
    eng: 'Pricing',
    ru: 'Стоимость услуги',
    uz: 'Xizmat narxi',
};

export const JobTypes = {
    eng: 'Job types',
    ru: 'Виды работ',
    uz: 'Ish turlari',
};

export const Edit_Service = {
    eng: 'Edit service',
    ru: 'Выберите виды работ',
    uz: 'Ish turlarini tanlang',
};

export const Service_Types = {
    eng: 'Service types',
    ru: 'Tип услуги',
    uz: 'Xizmat turlari',
};

export const Price_Info = {
    eng: 'Price info',
    ru: 'Стоимость услуги',
    uz: 'Xizmat narxi',
};

export const Switch_to_Pro = {
    eng: 'Switch to Pro',
    ru: 'Перейти к профилю специалиста',
    uz: 'Mutaxassis profiliga ótish',
};

export const Edit_profile = {
    eng: 'Edit profile',
    ru: 'Редактировать профиль',
    uz: 'Profil moslamalari',
};

export const Number_of_coins = {
    eng: 'Number of coins',
    ru: 'Количество монет',
    uz: 'Coin\'lar soni',
};

export const Drag_file_here_or_upload_it_manually = {
    eng: 'Upload photos',
    ru: 'Загрузить фото',
    uz: 'Rasm yuklash',
};


export const No_new_leads = {
    eng: 'There are no new Leads at this moment.',
    ru: 'На данный момент нет новых клиентов.',
    uz: 'Ayni paytda yangi mijozlar yo\'q.',
};

export const Check_availability_details_button = {
    eng: 'Request a service',
    ru: 'Заказать услугу',
    uz: 'Buyurtma berish',
};

export const Check_availability = {
    eng: 'Check availability',
    ru: 'Задать вопрос',
    uz: 'Ma\'lumot olish',
};

export const Event_type = {
    eng: 'Event type',
    ru: 'Тип события',
    uz: 'Hodisa turi',
};

export const Its_free_with_no_obligation_to_hire = {
    eng: 'It\'s free with no obligation to hire',
    ru: 'Услуга бесплатно, без обязательств по найму',
    uz: 'Yollash uchun majburiyat yo\'q va xizmat bepul',
};

export const Photos = {
    eng: 'Photos',
    ru: 'Фотографии',
    uz: 'Rasmlar',
};

export const No_photos_yet = {
    eng: 'No photos yet',
    ru: 'Фотографий пока нет',
    uz: 'Hali rasmlar yo\'q',
};

export const Videos = {
    eng: 'Videos',
    ru: 'Видео',
    uz: 'Videolar',
};

export const No_Comments = {
    eng: 'No Comments',
    ru: 'Нет комментариев',
    uz: 'Izoh yo\'q',
};

export const See_pros = {
    eng: 'See pros',
    ru: 'Посмотреть специалистов',
    uz: 'Mutaxassislarni ko\'rish',
};

export const See_all = {
    eng: 'See pros',
    ru: 'Увидеть все',
    uz: 'Hammasini ko\'rish',
};

export const Join_as_a_pro = {
    eng: 'Join as a pro',
    ru: 'Стать специалистом',
    uz: 'Mutaxassis bólib qóshiling',

};

export const Log_in = {
    eng: 'Log in',
    ru: 'Войти',
    uz: 'Kirish',
};

export const Password = {
    eng: 'Password',
    ru: 'Пароль',
    uz: 'Parol',
};

export const Old_password = {
    eng: 'Old password',
    ru: 'Прежний пароль',
    uz: 'Eski parol',
};

export const Skip = {
    eng: 'Skip',
    ru: 'Пропустить',
    uz: 'Keyingisi',
};

export const Reset = {
    eng: 'Reset',
    ru: 'Сброс',
    uz: 'Qayta o\'rnatish',
};

export const Filters = {
    eng: 'Filters',
    ru: 'Фильтры',
    uz: 'Filtrlar',
};

export const New_password = {
    eng: 'New password',
    ru: 'Новый пароль',
    uz: 'Yangi parol',
};

export const Change_Password = {
    eng: 'Change Password',
    ru: 'Сменить пароль',
    uz: 'Parolni o\'zgartirish',
};

export const Successfully_changed_password = {
    eng: 'Successfully changed password',
    ru: 'Пароль успешно изменен',
    uz: 'Parol muvaffaqiyatli o\'zgartirildi',
};

export const By_clicking_Create_Account_you_agree_to_the = {
    eng: 'By clicking Create Account, you agree to the',
    ru: 'Нажимая «Создать учетную запись», вы соглашаетесь с',
    uz:
    'Akkount yaratish tugmachasini bosish orqali siz foydalanish shartlariga rozilik bildirasiz.',
};

export const Create_Account = {
    eng: 'Create Account',
    ru: 'Регистрация',
    uz: 'Akkount yaratish',
};

export const Already_have_an_account = {
    eng: 'Already have an account?',
    ru: 'Уже есть аккаунт?',
    uz: 'Akkountingiz bormi?',
};

export const Dont_have_an_account = {
    eng: 'Don\'t have an account?',
    ru: 'У вас нет аккаунта?',
    uz: 'Akkountingiz yo\'qmi?',
};

export const Remember_me = {
    eng: 'Remember me',
    ru: 'Запомнить меня',
    uz: 'Meni eslab qolish',
};

export const Forgot_password = {
    eng: 'Forgot password?',
    ru: 'Забыли пароль?',
    uz: 'Parolni unutdingizmi?',
};

export const Welcome_back = {
    eng: 'Welcome back',
    ru: 'Добро пожаловать',
    uz: 'Xush kelibsiz',
};

export const Wrong_email_or_password = {
    eng: 'Wrong email or password',
    ru: 'Неправильный адрес электронной почты или пароль',
    uz: 'Noto\'g\'ri elektron pochta yoki parol',
};

export const Wrong_password = {
    eng: 'Wrong password',
    ru: 'Неправильный пароль',
    uz: 'Noto\'g\'ri parol',
};

export const please_enter_valid_email_address = {
    eng: 'Please enter valid email address',
    ru: 'Пожалуйста, введите действующий адрес электронной почты',
    uz: 'E-pochta manzilini to‘g‘ri kiriting',
};

export const this_field_is_required = {
    eng: 'This field is required',
    ru: 'Это поле обязательно к заполнению',
    uz: 'Ushbu qator to\'ldirilishi shart',
};

export const EMail_address_already_exists = {
    eng: 'E-Mail address already exists!',
    ru: 'Адрес электронной почты уже существует!',
    uz: 'Elektron pochta manzili allaqachon mavjud!',
};

export const password_should_be_minimum_4_characters = {
    eng: 'Password should be at least 4 letters.',
    ru: 'Пароль должен состоять минимум из 4 букв.',
    uz: 'Parol kamida 4 ta harfdan iborat bōlishi kerak',
};

export const Passwords_dont_match = {
    eng: 'Passwords don\'t match',
    ru: 'Подтверждение не совпадает с паролем',
    uz: 'Yangi parol va tasdiqlovchi parol bir xil emas',
};

export const Text_is_required = {
    eng: 'Text is required.',
    ru: 'Текст обязателен.',
    uz: 'Matn yozilishi shart',
};

export const write_old_password = {
    eng: 'write old password',
    ru: 'введите старый пароль',
    uz: 'eski parolni kiriting',
};

export const Enter_the_service_name = {
    eng: 'Enter the service name',
    ru: 'Введите название услуги',
    uz: 'Xizmat nomini kiriting',
};

export const Find_your_ideal_client = {
    eng: 'Find your ideal client',
    ru: 'Найдите для себя идеального клиента',
    uz: 'O\'zingiz uchun eng a\'lo mijozni toping',
};

export const Focus_on_the_right_customers = {
    eng: 'Focus on the right customers',
    ru: 'Сосредоточьтесь на подходящих клиентах',
    uz: 'Aynan sizga mos keladigan mijozlarga e\'tiboringizni qarating',
};

export const Study_the_desires_and_requirements = {
    eng:
    'Study the desires and requirements of customers and filter those whose requests do not suit you',
    ru:
    'Изучайте желания и требования клиентов и отфильтруйте тех, чьи запросы не подходят вам',
    uz:
    'Mijozlarning istaklari va talablarini o\'rganing hamda so\'rovlari sizga mos bo\'lmaganlarni filtrlang',
};

export const Start_taking_orders_continuously = {
    eng: 'Start taking orders continuously',
    ru: 'Начните принимать заказы беспрерывно',
    uz: 'Doimiy ravishda buyurtmalar qabul qilishni boshlang',
};

export const Increase_your_rating_and_get_more_orders = {
    eng: 'Increase your rating and get more orders',
    ru: 'Повышайте ваш рейтинг и получайте больше заказов',
    uz: 'Reytingingizni oshiring va ko\'proq buyurtmalar qabul qiling',
};

export const Convenience_and_ease_of_use = {
    eng: 'Convenience and ease of use',
    ru: 'Удобность и простота в использовании',
    uz: 'Foydalanishdagi qulaylik va osonlik',
};

export const Promote_your_candidacy_with_our_guides = {
    eng: 'Promote your candidacy with our guides',
    ru: 'Продвигайте свою кандидатуру с помощью наших гайдов',
    uz: 'Bizning ko\'rsatmalar bilan o\'z nomzodingizni targ\'ib eting.',
};

export const Helping_You_Grow_Your_Business = {
    eng: 'Helping You Grow Your Business',
    uz: 'Iste\'dodingiz yordamida daromad oling',
    ru: 'Зарабатывайте на том, что умеете',
};
export const NotifiCationInfo={
    eng: 'Notifications',
    ru: 'Уведомления',
    uz: 'Xabarnoma',

};
export const ContactInfo={
    eng: 'Contact Details',
    ru: 'Контактные данные',
    uz: 'Shaxsiy ma\'lumotlar'
};
export const EmailInfo={

    eng: 'Change Email',
    ru: 'Изменить Email',
    uz: 'Email\'ni ózgartirish'
};
export const PasswordInfo={
    eng: 'Change password',
    ru: 'Изменить пароль',
    uz: 'Parolni ózgartirish'
};
export const DeactivateInfo={

    eng: 'Deactivate account',
    ru: 'Удалить учетную запись',
    uz: 'Akkauntni óchirish'
};
export const  QuotesInfo={
    eng: 'Quote templates',
    ru: 'Шаблоны сообщений',
    uz: 'Xabar shablonlari'
};
export const AccountIn={
    eng: 'Account',
    ru: 'Аккаунт',
    uz: 'Akkaunt'
};
export const Joined={
    eng: 'Joined',
    ru: 'присоединился',
    uz: 'qóshilgan sana'
};
export const PaymentInfo={
    eng: 'Payments',
    ru: 'Платежи и счёт',
    uz: 'Tólov va hisob'
};
export const When_service_needed = {
    eng: 'When is the service needed?',
    ru: 'Когда нужна услуга?',
    uz: 'Xizmat qachon kerak?',
};

export const January = {
    eng: 'January',
    ru: 'января',
    uz: 'yanvar', // TODO
};
export const yourfullName={
    eng: 'Your full name',
    ru: 'Ваше полное имя',
    uz: `Tóliq ismingiz`,
};

export const February = {
    eng: 'February',
    ru: 'февраля',
    uz: 'fevral', // TODO
};
export const phoneNumber= {
    eng: 'Phone number',
    ru: 'Мобильный номер',
    uz: 'Mobil raqamingiz',
};
export const March = {
    eng: 'March',
    ru: 'марта',
    uz: 'mart', // TODO
};

export const April = {
    eng: 'April',
    ru: 'апреля',
    uz: 'aprel', // TODO
};

export const May = {
    eng: 'May',
    ru: 'мая',
    uz: 'may',
};

export const June = {
    eng: 'June',
    ru: 'июня',
    uz: 'iyun',
};

export const July = {
    eng: 'July',
    ru: 'июля',
    uz: 'iyul',
};

export const August = {
    eng: 'August',
    ru: 'августа',
    uz: 'avgust',
};

export const September = {
    eng: 'September',
    ru: 'сентября',
    uz: 'sentyabr',
};

export const October = {
    eng: 'October',
    ru: 'октября',
    uz: 'oktyabr',
};

export const November = {
    eng: 'November',
    ru: 'ноября',
    uz: 'noyabr',
};

export const December = {
    eng: 'December',
    ru: 'декабря',
    uz: 'dekabr',
};

export const languageFormat = (item, lang) => {
    if (item[lang] && item[lang].length > 0) {
        return item[lang];
    }
    return item['eng'];
};

export const GetConfirmationCode = {
    eng: 'Get confirmation code',
    ru: 'Получить код подтверждения',
    uz: 'Tasdiqlash kodini oling'
};

export default {
    yourFullName: {
        eng: 'Your full name',
        ru: 'Ваше полное имя',
        uz: `Tóliq ismingiz`,
    },
    password: {
        eng: 'Password',
        ru: 'Пароль',
        uz: 'Parol',
    },
    createPassword: {
        eng: 'Password',
        ru: 'Придумайте пароль',
        uz: 'Parol',
    },
    phoneNumber: {
        eng: 'Phone number',
        ru: 'Мобильный номер',
        uz: 'Mobil raqamingiz',
    },
    recieveCode: {
        eng: 'Receive a code',
        ru: 'Получить код',
        uz: 'Kodni yubor',
    },
    recieveCodeAgain: {
        eng: 'receive again',
        ru: 'получить снова',
        uz: 'qaytadan yubor',
    },
    enterSmsCode: {
        eng: 'SMS code:',
        ru: 'Введите код из СМС',
        uz: 'SMS kodni kiriting',
    },
    termsOfUse: {
        eng: 'By clicking Create Account, you agree to the Terms of Use.',
        ru: 'Нажимая «Создать учетную запись», вы соглашаетесь с Условиями использования.',
        uz: '«Róyxatdan ótish» tugmasini bosar ekanman, foydalanish shartlariga roziman.',
    },
    termsOfUseProceed: {
        eng: 'By clicking "Proceed", you agree to the Terms of Use.',
        ru: 'Нажимая «Продолжить», вы соглашаетесь с Условиями использования.',
        uz: '«Davom etish» tugmasini bosar ekanman, foydalanish shartlariga roziman.',
    },
    termsOfUsePhone: {
        eng: 'By confirming your number, you agree to the Terms of Use.',
        ru: 'Подтверждая свой номер, вы соглашаетесь с Условиями использования.',
        uz: 'Raqamingizni tasdiqlash orqali siz Foydalanish shartlariga rozilik bildirasiz.',
    },
    createAccount: {
        eng: 'Create Account',
        ru: 'Создать учетную запись',
        uz: 'Róyhatdan ótish',
    },
    resetPassword: {
        eng: 'Change Password',
        ru: 'Поменять пароль',
        uz: 'Parolni o\'zgartirish'
    },
    thanksForJoining: {
        eng: 'Your profile is ready to use!',
        ru: 'Ваш профиль готов к использованию!',
        uz: 'Sizning profilingiz foydalanishga tayyor!',
    },
    accountConfirmationDisclaimer: {
        eng: 'Please enter your number below to access your profile.',
        ru: 'Пожалуйста, введите свой номер ниже чтобы получить доступ к профилю.',
        uz: 'Iltimos, profilga kirish uchun mobil raqamingizni kiriting.',
    },
    haveQuestions: {
        eng: 'Questions?',
        ru: 'Есть вопросы?',
        uz: 'Savolingiz bormi?',
    },
    callUs: {
        eng: 'Call us 71-269-4979',
        ru: 'Позвоните нам 71-269-4979',
        uz: 'Bizga bog\'laning 71-269-4979',
    },
    wantToJoinPro: {
        eng: 'I want to join the best specialist list!',
        ru: 'Хочу попасть в список лучших специалистов!',
        uz: 'Eng zór mutaxassislar róyxatiga qóshilishni istayman!',
    },
    confirmNumberCTA: {
        eng: 'CONFIRM MY NUMBER',
        ru:  'ПОДТВЕРДИТЬ МОЙ НОМЕР',
        uz:  'MOBIL RAQAMNI TASDIQLASH',
    }

};

export const confirmProText = {
    0: {
        title: {
            eng: 'We show customers your business for free',
            ru: 'Мы бесплатно показываем клиентам ваш бизнес',
            uz: 'Biz mijozlarga sizning xizmatingizni bepul kórsatamiz',

        },
        content: {
            eng: 'Advertising is the most important part of a business. With TOPX.UZ you will have a free page available anytime. All you need is to make your page attractable and great customer service. You will learn and can grow on your own along the career ladder.',
            ru: 'Реклама - самая важная часть бизнеса. С TOPX.UZ у вас будет готовая реклама для клиентов, и в этом процессе вы сами будете напрямую участвовать, изучать и можете расти сами по себе по карьерной лестнице - легко,  просто и всегда выгодно абсолютно для всех.',
            uz: 'Aynan reklama biznesning eng muhim qismi hisoblanadi. Sizga TOPX.UZ mijozlar uchun tayyor reklama taqdim etadi va bu jarayonda siz o\'zingiz to\'g\'ridan-to\'g\'ri ishtirok etasiz, o’rganasiz hamda o\'z ustingizda ishlash, karyera pog\'onasidan qat\'iyat bilan ko\'tarilishga imkoningiz bo’ladi. Bu oson, tushunarli va har doim hamma uchun foydalidir.',
        },
    },


    1: {
        title: {
            eng: 'Clients choose the professionals they like',
            ru: 'Клиенты выбирают специалистов, которые им нравятся',
            uz: 'Mijozlar o\'zlariga yoqadigan mutaxassislarni tanlaydilar',
        },
        content: {
            eng:  'In search of a professional, a client always has difficulties in finding answers to his important questions. Not all clients receive answers and almost everyone can face this. Show yourself worthy, answer all inquiries on time, and you will be among the TOPs! Since quality service is the best advertisement!',
            ru: 'В поисках профессионала, для клиента всегда есть трудности в нахождении ответов на своих важных вопросов. Ответов получают не все клиенты и с этим может столкнуться почти каждый.  Покажите себя достойно, отвечайте вовремя на все запросы, и вы будете среди ТOПовых! Так как, качественное обслуживание - наилучшая реклама!',
            uz: 'Mijoz uchun professional topish jarayonidagi o\'zining muhim savollariga javob topishda doim qiyinchiliklar uchrab turadi va bunday muammoga deyarli barcha duch kelishi mumkin.  Siz o\'zingizni munosib nayomon qiling, barcha so\'rovlarga o\'z vaqtida javob bering, shunda siz TOPlar qatoriga kirasiz! Zero sifatli xizmat bu eng yaxshi reklamadir!'
        }
    },


    2: {
        title: {
            eng: 'You choose your clients too',
            ru: 'Вы тоже выбираете своих клиентов',
            uz: 'Siz ham o\'z mijozlaringizni o\'zingiz tanlaysiz',
        },
        content: {
            eng: 'On the TOPX.UZ website, everyone can choose whoever they think suits them in different categories of professionalism, since for us every professional is equally important, like every client. We will try to make everyone feel comfortable and profitable! Find your ideal clients with TOPX.UZ!',
            ru: 'На сайте TOPX.UZ все могут выбрать того, кого считают, что им подходит по разным категориям профессионализма, так как для нас каждый профессионал важен одинаково, как и каждый клиент. Мы постараемся чтобы всем было удобно, комфортно и выгодно! С TOPX.UZ найдите идеальных клиентов!',
            uz: 'TOPX.UZ saytida har kim o\'ziga mos keladigan turli xil toifadagi professionallik darajasidan kelib chiqqan holda tanlov qilishi mumkin, chunki biz uchun har bir mutaxassis har bir mijoz singari bir xil muhim ahamiyatga ega. Biz barcha uchun  qulay, komfort muhit hamda foydali afzalliklar yaratishga harakat qilamiz! TOPX.UZ yordamida o\'zingiz uchun ideal mijozlarni toping!',
        },
    },
    3: {
        title: {
            eng:  'Respond quickly and get the job',
            ru: 'Отвечайте быстро и получите работу',
            uz: 'O\'z vaqtida javob bering va xizmatga qabul qiling',
        },
        content: {
            eng: 'Promptness – is the most important sign of professionalism. Therefore, keeping in touch will help you continually receive inquiries from potential customers and increase your profits. No difficulties, we will show, explain and support everything!',
            ru: 'Оперативность — это наиболее важный признак профессионализма. Поэтому если будете всегда на связи, это поможет вам беспрерывно получать запросы от потенциальных клиентов и увеличить свой прибыль. Никаких трудностей, мы все покажем, объясним и поддержим!',
            uz: 'Tezkorlik - bu professionallikning eng muhim belgisidir. Shu sababli, doimiy aloqada bo\'lishingiz potentsial mijozlardan uzliksiz ravishda so\'rovlar olishingizga va daromadingizni oshirib borishda katta yordam beradi. Biz sizga hamma narsani ko\'rsatamiz, tushuntiramiz va qo\'llab-quvvatlaymiz!  TOPX.UZ sizga yuqori pog\'onalarga ko\'tarilishingizda yordam beradi.',
        },
    },
};

export const confirmProHead = {
    title: {
        eng: 'How TOPX works?',
        ru: 'Как работает TOPX?',
        uz: 'TOPX qanday ishlaydi?',
    },
    text: {
        eng: 'TOPX is a new marketplace that is growing every day. Our mission is to facilitate the search process for both the client and the specialist.',
        ru: 'TOPX - довольно новый формат платформы, которая будет совершенствоваться с каждым днем. Наша миссия - облегчить процесс поиска как для клиента, так и для специалистов.',
        uz: 'TOPX - har kuni ósib borayotgan yangi platforma. Bizning vazifamiz ham mijoz, ham mutaxassis uchun qidiruv jarayonini yengillashtirishdir.',
    }
};

export const reviewAccountInfo = {
    businessDetails: {
        eng: 'Details of your business profile',
        ru: 'Детали вашего бизнес-профиля',
        uz: 'Biznes profilingiz ma\'lumotlari',
    },
    name: {
        eng: 'Your name / Company name',
        ru: 'Ваше имя / Название фирмы',
        uz: 'Sizning ismingiz / Firma nomi',
    },
    service: {
        eng: 'Service',
        ru: 'Услуга',
        uz: 'Xizmat',
    },
    mobileNumber: {
        eng: 'Mobile number (your login)',
        ru: 'Мобильный номер (будет использоваться как логин)',
        uz: 'Mobil raqam (login sifatida ishlatiladi)',
    },
    addEmail: {
        eng: 'Add Email',
        ru: 'Добавить Email',
        uz: 'Email qóshish',
    },
    invalidEmail: {
        eng: 'enter a valid Email address',
        ru: 'введите действующий Email адрес',
        uz: 'yaroqli Email manzilni kiriting',
    },
    changeLaterDisclaimer: {
        eng: 'You can change your account details by clicking "Proceed".',
        ru:  'Вы сможете изменить данные аккаунта, нажав «Продолжить».',
        uz:  '"Davom etish" tugmasini bosib profil ma\'lumotlarini ózgartirishingiz mumkin.',
    },
    continue: {
        eng: 'Proceed',
        ru: 'Продолжить',
        uz: 'Davom etish',
    },
};

export const NoPRojects={

    eng: 'You have not started a project yet. ',
    ru: 'Вы еще не начали проект.',
    uz: 'Siz hali loyiha boshlamadingiz.'
};
export const NoPRojects2={

    eng: ' Start searching for the professionals you need!',
    ru: ' Начните искать нужных вам профессионалов!',
    uz: ' Sizga zarur mutaxassislarni qidirishni boshlang!'
};

export const errorCodes = {
    accountAlreadyVerifiedError: {
        eng: 'This account has already been verified',
        ru: 'Этот аккаунт уже подтвержден',
        uz: 'Akkaunt tasdiqlanib bólgan',
    },


    incorrectValidationCode: {
        eng: 'incorrect code',
        ru: 'неверный код',
        uz: 'kod notóg\'ri',
    },
    accountNotExisting: {
        eng: 'This account does not exist. Please try again or contact us.',
        ru: 'Этот аккаунт не существует. Попробуйте еще раз или свяжитесь с нами.',
        uz: 'Hisob mavjud emas. Iltimos, qayta urinib kóring yoki biz bilan bog\'laning.',
    },
    proAccountNotExisting: {
        eng: 'This account (pro) does not exist. Please try again or contact us.',
        ru: 'Этот аккаунт (pro) не существует. Попробуйте еще раз или свяжитесь с нами.',
        uz: 'Hisob (pro) mavjud emas. Iltimos, qayta urinib kóring yoki biz bilan bog\'laning.',
    },
    wrongPassword: {
        eng: 'Wrong password',
        ru: 'Неправильный пароль',
        uz: 'Noto\'g\'ri parol',
    },
    accountAlreadyExisting: {
        eng: 'Account is already existing',
        ru: 'Профиль уже существует',
        uz: 'Account is already existing',
    }
};

export const noReviews = {
    eng: 'No reviews',
    ru: 'Нет отзывов',
    uz: 'Sharhlar yo\'q',
};

export const reviewRating = {
    superb: {
        eng: 'Superb',
        ru: 'Безупречно',
        uz: 'Mukammal',
    },
    veryGood: {
        eng: 'Very good',
        ru: 'Отлично',
        uz: 'Juda yaxshi',
    },
    good: {
        eng: 'Good',
        ru: 'Похвально',
        uz: 'Yaxshi',
    },
    satisfactory: {
        eng: 'Satisfactory',
        ru: 'Удовлетворительно',
        uz: 'Qoniqarli',
    },
    average: {
        eng: 'Average',
        ru: 'Неплохо',
        uz: 'O\'rtacha',
    },
    poor: {
        eng: 'Poor',
        ru: 'Плохо',
        uz: 'Yomon',
    },
    terrible: {
        eng: 'Terrible',
        ru: 'Ужасно',
        uz: 'Juda yomon',
    }
};

export const readMore = {
    eng: 'More...',
    ru: 'Больше...',
    uz: 'Hammasi...',
};

export const share = {
    eng: 'Share',
    ru: 'Поделиться',
    uz: 'Ulashing',
};